// Copyright (C) 2020 Humanome Lab., Inc.

import './styles.scss';
import React from 'react';
import Menu, { ClickParam } from 'antd/lib/menu';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import Icon from 'antd/lib/icon';
import Text from 'antd/lib/typography/Text';

import { useTranslation } from 'react-i18next';
import { MenuIcon } from 'icons';

import DumpSubmenu from './dump-submenu';
import LoadSubmenu from './load-submenu';
import ExportSubmenu from './export-submenu';

interface Props {
    taskID: number;
    taskMode: string;
    bugTracker: string;
    loaders: string[];
    dumpers: string[];
    isStaff: boolean;
    jobsLength: number;
    // exporters: string[];
    loadActivity: string | null;
    dumpActivities: string[] | null;
    exportActivities: string[] | null;
    installedTFAnnotation: boolean;
    installedTFSegmentation: boolean;
    installedAutoAnnotation: boolean;
    inferenceIsActive: boolean;
    isTraining: boolean;
    onClickMenu: (params: ClickParam, file?: File) => void;
}

export enum Actions {
    TRAIN_MODEL = 'train_model',

    DUMP_TASK_ANNO = 'dump_task_anno',
    LOAD_TASK_ANNO = 'load_task_anno',
    EXPORT_TASK_DATASET = 'export_task_dataset',
    COPY_TASK = 'copy_task',
    DELETE_TASK = 'delete_task',

    RUN_AUTO_ANNOTATION = 'run_auto_annotation',
    OPEN_BUG_TRACKER = 'open_bug_tracker',
}

export default function ActionsMenuComponent(props: Props): JSX.Element {
    const {
        taskID,
        taskMode,
        // bugTracker,
        installedAutoAnnotation,
        installedTFAnnotation,
        installedTFSegmentation,
        inferenceIsActive,
        dumpers,
        loaders,
        isStaff,
        jobsLength,
        // exporters,
        onClickMenu,
        dumpActivities,
        exportActivities,
        loadActivity,
        isTraining,
    } = props;
    dumpers.sort();
    loaders.sort();

    const { t } = useTranslation();

    const renderModelRunner = installedAutoAnnotation ||
        installedTFAnnotation || installedTFSegmentation;

    let latestParams: ClickParam | null = null;
    function onClickMenuWrapper(params: ClickParam | null, file?: File): void {
        const copyParams = params || latestParams;
        if (!copyParams) {
            return;
        }
        latestParams = copyParams;

        if (copyParams.keyPath.length === 2) {
            const [, action] = copyParams.keyPath;
            if (action === Actions.LOAD_TASK_ANNO) {
                if (file) {
                    Modal.confirm({
                        title: t('Current annotation will be lost'),
                        content: t('You are going to upload new annotations to this task Continue?'),
                        className: 'cvat-modal-content-load-task-annotation',
                        onOk: () => {
                            onClickMenu(copyParams, file);
                        },
                        okButtonProps: {
                            type: 'danger',
                        },
                        okText: 'Update',
                    });
                }
            } else {
                onClickMenu(copyParams);
            }
        } else if (copyParams.key === Actions.COPY_TASK) {
            Modal.confirm({
                title: t('The task {{taskID}} will be copied', { taskID }),
                content: t('All related data (images, annotations) will be copied Continue?'),
                className: 'cvat-modal-confirm-delete-task',
                onOk: () => {
                    onClickMenu(copyParams);
                },
                okButtonProps: {
                    type: 'danger',
                },
                okText: 'Copy',
            });
        } else if (copyParams.key === Actions.DELETE_TASK) {
            Modal.confirm({
                title: t('The task {{taskID}} will be deleted', { taskID }),
                content: t('All related data (images, annotations) will be lost Continue?'),
                className: 'cvat-modal-confirm-delete-task',
                onOk: () => {
                    onClickMenu(copyParams);
                },
                okButtonProps: {
                    type: 'danger',
                },
                okText: 'Delete',
            });
        } else {
            onClickMenu(copyParams);
        }
    }

    return (
        <Dropdown
            overlay={
                (
                    <Menu
                        selectable={false}
                        className='cvat-actions-menu'
                        onClick={onClickMenuWrapper}
                    >
                        {
                            DumpSubmenu({
                                taskMode,
                                dumpers,
                                dumpActivities,
                                menuKey: Actions.DUMP_TASK_ANNO,
                                jobsLength,
                            })
                        }
                        {
                            LoadSubmenu({
                                loaders,
                                loadActivity,
                                inferenceIsActive,
                                isTraining,
                                onFileUpload: (file: File): void => {
                                    onClickMenuWrapper(null, file);
                                },
                                menuKey: Actions.LOAD_TASK_ANNO,
                                jobsLength,
                            })
                        }
                        {!!isStaff &&
                            (
                                ExportSubmenu({
                                    exporters: dumpers,
                                    exportActivities,
                                    menuKey: Actions.EXPORT_TASK_DATASET,
                                    jobsLength,
                                })
                            )}
                        {
                            renderModelRunner &&
                            (
                                <Menu.Item
                                    disabled={isTraining || !!loadActivity || inferenceIsActive || jobsLength === 0}
                                    key={Actions.RUN_AUTO_ANNOTATION}
                                >
                                    {t('AI annotation')}
                                </Menu.Item>
                            )
                        }
                        <hr />
                        <Menu.Item
                            key={Actions.DELETE_TASK}
                            disabled={isTraining || !!loadActivity || inferenceIsActive}
                        >
                            {t('Delete task')}
                        </Menu.Item>
                    </Menu>
                )
            }
            trigger={['hover', 'click']}
        >
            <Button size='large'>
                <Text className='cvat-text-color'>{t('Actions')}</Text>
                <Icon className='cvat-menu-icon' component={MenuIcon} />
            </Button>
        </Dropdown>

    );
}
