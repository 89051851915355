// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import Checkbox from 'antd/lib/checkbox';
import Select from 'antd/lib/select';
import { Row, Col } from 'antd/lib/grid';
import { Tooltip } from 'antd';
// import Text from 'antd/lib/typography/Text';
import { withTranslation, WithTranslation } from 'react-i18next';
import { changeLanguage } from 'i18n';
import { passwordValidator, usernameValidator } from 'utils/validation';

// import passwordReset from 'components/password-reset';

import { UserAgreement, Language } from 'reducers/interfaces';

export interface UserConfirmation {
    name: string;
    value: boolean;
}

export interface RegisterData {
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    organization: string;
    address: string;
    // phoneNumber: string;
    lang: string;
    purpose: string;
    oldPassword: string;
    password1: string;
    password2: string;
    confirmations: UserConfirmation[];
}

type RegisterFormProps = {
    fetching: boolean;
    userAgreements: UserAgreement[];
    onSubmit(registerData: RegisterData): void;
    initialData: RegisterData;
    passwordRequired: boolean;
} & FormComponentProps & WithTranslation;

class RegisterFormComponent extends React.PureComponent<RegisterFormProps> {
    private emailInput = React.createRef<Input>();

    public componentDidMount(): void {
        this.emailInput.current?.focus();
    }

    private getLanguage(): string {
        const { i18n } = this.props;
        return i18n.language ? i18n.language : Language.English;
    }

    private validateConfirmation = (rule: any, value: any, callback: any): void => {
        const { form, passwordRequired } = this.props;
        const { t } = this.props;
        if (!passwordRequired && !value && form.getFieldValue('password1')) {
            callback(t('Please confirm your password!'));
        }
        if (value && value !== form.getFieldValue('password1')) {
            callback(t('Two passwords that you enter is inconsistent!'));
        } else {
            callback();
        }
    };

    private validatePassword = (_: any, value: any, callback: any): void => {
        const { form, passwordRequired } = this.props;
        const { t } = this.props;
        if (passwordRequired || value) {
            const msg = passwordValidator(value);
            if (msg) {
                callback(t(msg));
            }

            if (value) {
                form.validateFields(['password2'], { force: true });
            }
        }
        callback();
    };

    private validateUsername = (_: any, value: any, callback: any): void => {
        const { t } = this.props;
        const msg = usernameValidator(value);
        if (msg) {
            callback(t(msg));
        }

        callback();
    };

    private validateAgrement = (agreement: any, value: any, callback: any): void => {
        const { userAgreements } = this.props;
        const { t } = this.props;
        let isValid = true;
        for (const userAgreement of userAgreements) {
            if (agreement.field === userAgreement.name &&
                userAgreement.required && !value) {
                isValid = false;
                callback(t('You must accept the {{userAgreement_text}} to continue!', { userAgreement_text: t(userAgreement.displayText) }));
                break;
            }
        }
        if (isValid) {
            callback();
        }
    };

    private handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        const {
            form,
            onSubmit,
            userAgreements,
        } = this.props;

        form.validateFields((error, values): void => {
            if (!error) {
                // eslint-disable-next-line no-param-reassign
                values.confirmations = [];

                for (const userAgreement of userAgreements) {
                    values.confirmations.push({
                        name: userAgreement.name,
                        value: values[userAgreement.name],
                    });
                    // eslint-disable-next-line no-param-reassign
                    delete values[userAgreement.name];
                }

                onSubmit(values);
            }
        });
    };

    private renderFirstNameField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('firstName', {
                    initialValue: initialData.firstName,
                    rules: [{
                        required: true,
                        message: t('Please specify a first name'),
                    }],
                })(
                    <Input
                        prefix={<Icon type='user-add' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('First name')}
                    />,
                )}
            </Form.Item>
        );
    }

    private renderLastNameField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('lastName', {
                    initialValue: initialData.lastName,
                    rules: [{
                        required: true,
                        message: t('Please specify a last name'),
                    }],
                })(
                    <Input
                        prefix={<Icon type='user-add' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Last name')}
                    />,
                )}
            </Form.Item>
        );
    }

    private renderUsernameField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('userName', {
                    initialValue: initialData.userName,
                    rules: [{
                        required: true,
                        message: t('Please specify a user name'),
                    }, {
                        validator: this.validateUsername,
                    }],
                })(
                    <Input
                        prefix={<Icon type='user-add' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Username')}
                    />,
                )}
            </Form.Item>
        );
    }

    private renderEmailField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('email', {
                    initialValue: initialData.email,
                    rules: [{
                        type: 'email',
                        message: t('The input is not valid E-mail!'),
                    }, {
                        required: true,
                        message: t('Please specify an email address'),
                    }],
                })(
                    <Input
                        autoComplete='email'
                        prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Email address')}
                        type='email'
                        ref={this.emailInput}
                    />,
                )}
            </Form.Item>
        );
    }

    private renderOldPasswordField(): JSX.Element {
        const { form, passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('oldPassword', {
                    rules: [{
                        required: passwordRequired,
                        message: t('Please input your old password!'),
                    }, {
                        validator: this.validatePassword,
                    }],
                })(<Input.Password
                    prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={t('Current password (if you want to change)')}
                />)}
            </Form.Item>
        );
    }

    private renderPasswordField(): JSX.Element {
        const { form, passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('password1', {
                    rules: [{
                        required: passwordRequired,
                        message: t('Please input your password!'),
                    }, {
                        validator: this.validatePassword,
                    }],
                })(<Input.Password
                    autoComplete='new-password'
                    prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={passwordRequired ? t('Password') : t('New password')}
                />)}
            </Form.Item>
        );
    }

    private renderOrganizationField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('organization', {
                    initialValue: initialData.organization,
                    rules: [{
                        required: true,
                        message: t('Please specify your organization'),
                    }],
                })(
                    <Input
                        prefix={<Icon type='team' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Organization')}
                    />,
                )}
            </Form.Item>
        );
    }

    private renderAddressField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('address', {
                    initialValue: initialData.address,
                    rules: [{
                        required: true,
                        message: t('Please specify your address'),
                    }],
                })(
                    <Input
                        prefix={<Icon type='environment' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Address')}
                    />,
                )}
            </Form.Item>
        );
    }

    // private renderPhoneNumberField(): JSX.Element {
    //     const { form, initialData } = this.props;

    //     return (
    //         <Form.Item hasFeedback>
    //             {form.getFieldDecorator('phoneNumber', {
    //                 initialValue: initialData.phoneNumber,
    //                 rules: [{
    //                     required: true,
    //                     message: 'Please specify your phone number (e.g. +123456789012)',
    //                     pattern: patterns.validatePhone.pattern,
    //                 }],
    //             })(
    //                 <Input
    //                     prefix={<Icon type='user-add' style={{ color: 'rgba(0,0,0,.25)' }} />}
    //                     placeholder='Phone number'
    //                 />,
    //             )}
    //         </Form.Item>
    //     );
    // }

    private renderPurposeField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('purpose', {
                    initialValue: initialData.purpose,
                    rules: [{
                        required: true,
                        message: t('Please describe your purpose of use'),
                    }],
                })(
                    <Input
                        prefix={<Icon type='bulb' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Purpose of use')}
                        suffix={(
                            <Tooltip title={t('EXAMPLES_OF_PURPOSE')} placement='topRight'>
                                <Icon type='info-circle' style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip>
                        )}
                    />,
                )}
            </Form.Item>
        );
    }

    private renderLangField(): JSX.Element {
        const { form, t } = this.props;

        return (
            <Row style={{ marginTop: '20px', marginBottom: '20px' }} gutter={8}>
                <Col>
                    {form.getFieldDecorator('lang', {
                        initialValue: this.getLanguage(),
                        rules: [{
                            required: true,
                            message: t('Please specify a user name'),
                        }, {
                            validator: this.validateUsername,
                        }],
                    })(
                        <Select
                            onChange={(lang: Language): void => {
                                changeLanguage(lang);
                            }}
                        >
                            <Select.Option key='english' value={Language.English}>English</Select.Option>
                            <Select.Option key='japanese' value={Language.Japanese}>Japanese (日本語)</Select.Option>
                        </Select>,
                    )}
                </Col>
            </Row>
        );
    }

    private renderPasswordConfirmationField(): JSX.Element {
        const { form, passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('password2', {
                    rules: [
                        {
                            required: passwordRequired,
                            message: t('Please input your password!'),
                        }, {
                            validator: this.validateConfirmation,
                        }],
                })(<Input.Password
                    autoComplete='new-password'
                    prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={passwordRequired ? t('Confirm password') : t('Confirm new password')}
                />)}
            </Form.Item>
        );
    }

    private renderUserAgreements(): JSX.Element[] {
        const { form, userAgreements } = this.props;
        const { t } = this.props;
        const getUserAgreementsElements = (): JSX.Element[] => {
            const agreementsList: JSX.Element[] = [];
            const readAndAccept = `${t('I read and accept the')} `;
            for (const userAgreement of userAgreements) {
                agreementsList.push(
                    <Form.Item key={userAgreement.name}>
                        {form.getFieldDecorator(userAgreement.name, {
                            initialValue: false,
                            valuePropName: 'checked',
                            rules: [{
                                required: true,
                                message: t('You must accept to continue!'),
                            }, {
                                validator: this.validateAgrement,
                            }],
                        })(
                            <Checkbox className='eyes-text-line-height-1-5'>
                                {readAndAccept}
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href={userAgreement.url}
                                    className='eyes-display-text-nowrap'
                                >
                                    {t(userAgreement.displayText)}
                                </a>
                            </Checkbox>,
                        )}
                    </Form.Item>,
                );
            }
            return agreementsList;
        };

        return getUserAgreementsElements();
    }

    public renderNameFields(): JSX.Element {
        if (this.getLanguage() === Language.Japanese) {
            return (
                <Row gutter={8}>
                    <Col span={12}>
                        {this.renderLastNameField()}
                    </Col>
                    <Col span={12}>
                        {this.renderFirstNameField()}
                    </Col>
                </Row>
            );
        }
        return (
            <Row gutter={8}>
                <Col span={12}>
                    {this.renderFirstNameField()}
                </Col>
                <Col span={12}>
                    {this.renderLastNameField()}
                </Col>
            </Row>
        );
    }

    public render(): JSX.Element {
        const { fetching, passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form onSubmit={this.handleSubmit} className='login-form'>
                {this.renderLangField()}
                {this.renderEmailField()}
                {this.renderNameFields()}
                {this.renderUsernameField()}
                {this.renderOrganizationField()}
                {this.renderAddressField()}
                {/* {this.renderPhoneNumberField()} */}
                { !passwordRequired && this.renderOldPasswordField() }
                {this.renderPasswordField()}
                {this.renderPasswordConfirmationField()}
                {this.renderPurposeField()}
                {this.renderUserAgreements()}

                <Form.Item>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='register-form-button'
                        loading={fetching}
                        disabled={fetching}
                    >
                        {t('Submit')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create<RegisterFormProps>()(withTranslation()(RegisterFormComponent));
