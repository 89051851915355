/* eslint-disable max-classes-per-file */
import React from 'react';
import {
    Table,
    Icon,
    Input,
    InputNumber,
    Button,
    Select,
    Popconfirm,
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import { HAS_EXPORT_MODEL_PERM_GROUP } from 'containers/trained-model-page/trained-model-page';

type EditableTableProps = {
    data: any[];
    activateUser: (cognitoId: string) => void;
    deactivateUser: (cognitoId: string) => void;
    updateUser: (username: string, attributes: any[]) => void;
} & FormComponentProps;

interface EditableTableState {
    editingKey: string;
}

const defaultState = {
    editingKey: '',
};

const EditableContext = React.createContext({});

type EditableCellProps = {
    editing: boolean;
    dataIndex: string;
    title: string;
    inputType: string;
    record: any;
    index: any;
    children: any;
    restProps: any;
} & FormComponentProps;

class EditableCell extends React.Component<EditableCellProps> {
    getInput = (): JSX.Element => {
        const { inputType } = this.props;
        if (inputType === 'number') {
            return <InputNumber />;
        }
        if (inputType === 'boolean') {
            return (
                <Select>
                    <Select.Option value='true'>true</Select.Option>
                    <Select.Option value='false'>false</Select.Option>
                </Select>
            );
        }
        if (inputType === 'plan') {
            return (
                <Select>
                    <Select.Option value='Basic'>Basic</Select.Option>
                    <Select.Option value='Business'>Business</Select.Option>
                    <Select.Option value='Pro'>Pro</Select.Option>
                </Select>
            );
        }
        if (inputType === 'is_staff') {
            return (
                <Select style={{ width: 60 }}>
                    <Select.Option value='yes'>yes</Select.Option>
                    <Select.Option value='no'>no</Select.Option>
                </Select>
            );
        }
        return <Input />;
    };

    getFormItem = (getFieldDecorator: any): JSX.Element => {
        const {
            dataIndex,
            title,
            record,
            inputType,
        } = this.props;

        return (
            <Form.Item style={{ margin: 0 }}>
                {getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: inputType !== 'is_staff',
                            message: `Please Input ${title}!`,
                        },
                    ],
                    initialValue: record[dataIndex],
                })(this.getInput())}
            </Form.Item>
        );
    };

    renderCell = ({ getFieldDecorator }: any): JSX.Element => {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        } = this.props;

        return (
            <td {...restProps}>
                {editing ? (
                    this.getFormItem(getFieldDecorator)
                ) : (
                    children
                )}
            </td>
        );
    };

    render(): JSX.Element {
        return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
    }
}

class EditableTable extends React.Component<EditableTableProps, EditableTableState> {
    searchInput: Input | null = null;
    constructor(props: EditableTableProps) {
        super(props);
        this.state = defaultState;
    }
    getColumns(userStatusList: string[]): any[] {
        const columns = [{
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            fixed: 'left',
            sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            editable: true,
            ...this.getColumnSearchProps('email'),
        }, {
            title: 'User Status',
            dataIndex: 'user_status',
            key: 'user_status',
            align: 'center',
            filters: userStatusList.map((status) => ({ text: status, value: status })),
            onFilter: (value: any, record: any) => record.user_status === value,
            editable: false,
        }, {
            title: 'Email Verified',
            dataIndex: 'email_verified',
            key: 'email_verified',
            align: 'center',
            width: 100,
            filters: [{ text: 'true', value: 'true' }, { text: 'false', value: 'false' }],
            onFilter: (value: any, record: any) => record.email_verified === value,
            editable: true,
        }, {
            title: 'Plan',
            dataIndex: 'custom:plan',
            key: 'custom:plan',
            align: 'center',
            editable: true,
            sorter: (a: any, b: any): number => {
                function p2i(x: any): number {
                    const plan = x['custom:plan'];
                    if (plan === 'Basic') return 1;
                    if (plan === 'Pro') return 10;
                    if (plan === 'Business') return 100;
                    return 0;
                }
                if (a['custom:plan']) {
                    if (b['custom:plan']) return p2i(a) - p2i(b);
                    return 1;
                }
                if (b['custom:plan']) return -1;
                return 0;
            },
        }, {
            title: 'Billable Time',
            dataIndex: 'billable_time',
            key: 'billable_time',
            editable: false,
            align: 'center',
            sorter: (a: any, b: any): number => {
                if (a.billable_time) {
                    if (b.billable_time) return a.billable_time - b.billable_time;
                    return 1;
                }
                if (b.billable_time) return -1;
                return 0;
            },
            render: (value: number | null): JSX.Element => (
                <Text>{ value === null ? 'No Data' : value}</Text>
            ),
        }, {
            title: 'Created Date',
            dataIndex: 'created_date',
            key: 'created_date',
            editable: false,
            align: 'left',
            sorter: (a: any, b: any): number => new Date(a.created_date).getTime() - new Date(b.created_date).getTime(),
            render: (date: Date): JSX.Element => (
                <Text>{ date.toLocaleString() }</Text>
            ),
        }, {
            title: 'Last Modified Date',
            dataIndex: 'modified_date',
            key: 'modified_date',
            editable: false,
            align: 'left',
            sorter: (a: any, b: any): number =>
                new Date(a.modified_date).getTime() - new Date(b.modified_date).getTime(),
            render: (date: Date): JSX.Element => (
                <Text>{ date.toLocaleString() }</Text>
            ),
        }, {
            title: 'Activation',
            dataIndex: 'enabled',
            key: 'enabled',
            align: 'center',
            filters: [{ text: 'Activated', value: true }, { text: 'Deactivated', value: false }],
            onFilter: (value: any, record: { enabled: any; }) => record.enabled === value,
            render: (value: any, record: any): JSX.Element => {
                const { activateUser, deactivateUser } = this.props;
                const editable = this.isEditing(record);
                if (editable) {
                    return (value ? (
                        <Popconfirm
                            title='Sure to deactivate?'
                            onConfirm={() => {
                                this.forceUpdate();
                                deactivateUser(record.cognito_id);
                                this.cancel();
                            }}
                        >
                            <Button type='link'>Deactivate</Button>
                        </Popconfirm>
                    ) : (
                        <Popconfirm
                            title='Sure to activate?'
                            onConfirm={() => {
                                activateUser(record.cognito_id);
                                this.cancel();
                            }}
                        >
                            <Button type='link'>Activate</Button>
                        </Popconfirm>
                    ));
                }
                return (<Text>{ value ? 'Activated' : 'Deactivated' }</Text>);
            },
        }, {
            title: 'is_staff',
            dataIndex: 'is_staff',
            key: 'is_staff',
            align: 'center',
            width: 100,
            filters: [{ text: 'yes', value: 'yes' }],
            onFilter: (value: any, record: any) => record.is_staff === value,
            editable: true,
        }, {
            title: 'Export Model',
            dataIndex: 'export_model',
            key: 'export_model',
            align: 'center',
            width: 100,
            filters: [{ text: 'yes', value: 'yes' }],
            onFilter: (value: any, record: any) => record.export_model === value,
            editable: true,
        }, {
            title: 'Operation',
            dataIndex: 'operation',
            fixed: 'right',
            align: 'center',
            width: 90,
            render: (text: string, record: any) => {
                const { editingKey } = this.state;
                const editable = this.isEditing(record);
                return editable ? (
                    <span>
                        <EditableContext.Consumer>
                            {(form) => (
                                <Popconfirm
                                    title='Sure to submit?'
                                    onConfirm={() => this.save(form, record)}
                                >
                                    <Button type='link'>Save</Button>
                                </Popconfirm>
                            )}
                        </EditableContext.Consumer>
                        <Popconfirm
                            title='Sure to cancel?'
                            onConfirm={() => this.cancel()}
                        >
                            <Button type='link'>Cancel</Button>
                        </Popconfirm>
                    </span>
                ) : (
                    <Button
                        type='link'
                        disabled={editingKey !== ''}
                        onClick={() => this.edit(record.cognito_id)}
                    >
                        Edit
                    </Button>
                );
            },
        }];
        return columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            let inputType = 'text';
            if (col.dataIndex === 'email_verified') {
                inputType = 'boolean';
            } else if (col.dataIndex === 'custom:plan') {
                inputType = 'plan';
            } else if (col.dataIndex === 'is_staff') {
                inputType = 'is_staff';
            } else if (col.dataIndex === 'export_model') {
                inputType = 'is_staff';
            }
            return {
                ...col,
                onCell: (record: any) => ({
                    record,
                    inputType,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });
    }

    getColumnSearchProps = (dataIndex: string): any => ({
        filterDropdown: ({
            setSelectedKeys, selectedKeys, confirm, clearFilters,
        }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={confirm}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type='primary'
                    onClick={confirm}
                    icon='search'
                    size='small'
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={clearFilters} size='small' style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered: any) => (
            <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value: string, record: { [x: string]: { toString: () => string; }; }) => record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => this.searchInput?.select());
            }
        },
    });

    isEditing = (record: any): boolean => {
        const { editingKey } = this.state;
        return record.cognito_id === editingKey;
    };

    cancel = (): void => {
        this.setState({ editingKey: '' });
    };

    save(form: any, record: any): void {
        const { updateUser } = this.props;
        const attributes: any[] = [];
        const cognitoId = record.cognito_id;
        form.validateFields((error: any, row: any) => {
            if (error) return;
            if ('email' in row) {
                attributes.push({ Name: 'email', Value: row.email });
                // TODO: Cognito Identity Service Provider API sends validation code for
                // validation, but we do not implement this validation flow....
                // attributes.push({ Name: 'email_verified', Value: 'false' });
            }
            if ('email_verified' in row) {
                attributes.push({ Name: 'email_verified', Value: row.email_verified });
            }
            if ('custom:plan' in row) {
                attributes.push({ Name: 'custom:plan', Value: row['custom:plan'] });
            }
            if ('is_staff' in row && row.is_staff) {
                attributes.push({ is_staff: row.is_staff });
            }
            let newGroups = record.groups;
            let groupUpdated = false;
            if ('export_model' in row && row.export_model) {
                if (row.export_model === 'yes') {
                    if (newGroups.indexOf(HAS_EXPORT_MODEL_PERM_GROUP) < 0) {
                        newGroups = [...newGroups, HAS_EXPORT_MODEL_PERM_GROUP];
                        groupUpdated = true;
                    }
                } else if (newGroups.indexOf(HAS_EXPORT_MODEL_PERM_GROUP) >= 0) {
                    newGroups = newGroups.filter((x: string) => x !== HAS_EXPORT_MODEL_PERM_GROUP);
                    groupUpdated = true;
                }
            }
            if (groupUpdated) {
                attributes.push({ groups: newGroups });
            }
            updateUser(cognitoId, attributes);
        });
    }

    edit(cognitoId: string): void {
        this.setState({ editingKey: cognitoId });
    }

    public render(): JSX.Element {
        const components = {
            body: {
                cell: EditableCell,
            },
        };
        const {
            form,
            data,
        } = this.props;

        const order = ['CONFIRMED', 'UNCONFIRMED', 'ARCHIVED', 'COMPROMISED', 'UNKNOWN', 'RESET_REQUIRED', 'FORCE_CHANGE_PASSWORD'];
        const userStatusList = [...new Set(data.map((e) => e.user_status))].sort(
            (a, b) => order.indexOf(a) - order.indexOf(b),
        );

        return (
            <EditableContext.Provider value={form}>
                <Table
                    components={components}
                    bordered
                    dataSource={data}
                    columns={this.getColumns(userStatusList)}
                    rowKey='cognito_id'
                    rowClassName={() => 'editable-row'}
                    scroll={{
                        x: true,
                        scrollToFirstRowOnChange: true,
                    }}
                    pagination={{
                        onChange: this.cancel,
                        pageSize: 20,
                    }}
                    size='small'
                />
            </EditableContext.Provider>
        );
    }
}

export default Form.create<EditableTableProps>()(EditableTable);
