import React from 'react';
import { connect } from 'react-redux';

import { CombinedState, Model } from 'reducers/interfaces';
import DetectObjectTestComponent from 'components/trained-model-page/detect-object-test';


interface StateToProps {
}

interface DispatchToProps {
}

interface OwnProps {
    model: Model;
}

function mapStateToProps(state: CombinedState): StateToProps {
    return {};
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {};
}

function DetectObjectTestContainer(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    const { model } = props;

    return (
        <DetectObjectTestComponent model={model} />
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DetectObjectTestContainer);
