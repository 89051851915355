// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Tabs from 'antd/lib/tabs';
import Icon from 'antd/lib/icon';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import { RouteComponentProps } from 'react-router';

import WorkspaceSettingsContainer from 'containers/settings-page/workspace-settings';
import PlayerSettingsContainer from 'containers/settings-page/player-settings';
import ProfileSettingsContainer from 'containers/settings-page/profile-settings';
import UpgradeContainer from 'containers/settings-page/upgrade';

function SettingsPage(props: RouteComponentProps): JSX.Element {
    const { t } = useTranslation('settingsPage');

    return (
        <div className='cvat-settings-page'>
            <Row type='flex' justify='center'>
                <Col>
                    <Text className='cvat-title'>
                        {t('Settings')}
                    </Text>
                </Col>
            </Row>
            <Row type='flex' justify='center'>
                <Col md={22} lg={22} xl={18} xxl={18}>
                    <Tabs
                        type='card'
                        tabBarStyle={{ marginBottom: '0px', marginLeft: '-1px' }}
                    >
                        <Tabs.TabPane
                            tab={
                                (
                                    <span className='settings-user'>
                                        <Icon type='user' />
                                        <Text>{t('Profile')}</Text>
                                    </span>
                                )
                            }
                            key='profile'
                        >
                            <ProfileSettingsContainer />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={
                                (
                                    <span className='settings-player'>
                                        <Icon type='play-circle' />
                                        <Text>{t('Player')}</Text>
                                    </span>
                                )
                            }
                            key='player'
                        >
                            <PlayerSettingsContainer />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={
                                (
                                    <span className='settings-workspace'>
                                        <Icon type='laptop' />
                                        <Text>{t('Workspace')}</Text>
                                    </span>
                                )
                            }
                            key='workspace'
                        >
                            <WorkspaceSettingsContainer />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={
                                (
                                    <span className='settings-upgrade'>
                                        <Icon type='pay-circle' />
                                        <Text>{t('Upgrade plan')}</Text>
                                    </span>
                                )
                            }
                            key='upgrade'
                        >
                            <UpgradeContainer />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
            <Row type='flex' justify='center'>
                <Col md={14} lg={12} xl={10} xxl={9} className='cvat-settings-page-back-button-wrapper'>
                    <Button
                        className='cvat-settings-page-back-button'
                        type='primary'
                        onClick={(): void => {
                            props.history.goBack();
                        }}
                    >
                        {t('Go Back')}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default SettingsPage;
