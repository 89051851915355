import React from 'react';

import { Link } from 'react-router-dom';

import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import store from 'store';
import {
    Col,
    Row,
    Button,
    Input,
} from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import { withTranslation, WithTranslation } from 'react-i18next';

interface PasswordResetFormProps extends FormComponentProps, WithTranslation {
    fetching: boolean;
    onSubmit: (email: string) => void;
}

interface PasswordResetFormState {
    email: string;
}

class PasswordResetFormComponent extends React.Component<PasswordResetFormProps, PasswordResetFormState> {
    private emailInput = React.createRef<Input>();

    constructor(props: any) {
        super(props);
        this.state = {
            email: store.get('email'),
        };
    }

    public componentDidMount(): void {
        this.emailInput.current?.focus();
    }

    render(): JSX.Element {
        const { fetching, form, onSubmit } = this.props;
        const { t } = this.props;
        const { email } = this.state;

        return (
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    form.validateFields((error): void => {
                        if (!error) {
                            onSubmit(email);
                        }
                    });
                }}
            >
                <Form.Item>
                    {form.getFieldDecorator('email', {
                        rules: [{
                            required: true,
                            message: t('Please specify an email address'),
                        }],
                    })(
                        <Input
                            name='email'
                            onChange={(e) => this.setState({ email: e.target.value })}
                            placeholder='Email address'
                            type='email'
                            ref={this.emailInput}
                        />,
                    )}
                </Form.Item>
                <Button
                    type='primary'
                    htmlType='submit'
                    loading={fetching}
                    disabled={fetching}
                >
                    {t('Reset')}
                </Button>
            </Form>
        );
    }
}

const PasswordResetForm =
    Form.create<PasswordResetFormProps>()(withTranslation()(PasswordResetFormComponent));

function PasswordResetComponent(
    props: PasswordResetFormProps,
): JSX.Element {
    const sizes = {
        xs: { span: 15 },
        sm: { span: 14 },
        md: { span: 10 },
        lg: { span: 8 },
        xl: { span: 6 },
    };

    const { t } = props;

    return (
        <Row type='flex' justify='center' align='middle'>
            <Col {...sizes}>
                <Title level={2}>
                    {t('Reset password of your account')}
                </Title>
                <PasswordResetForm {...props} />
                <Row
                    style={{ marginTop: 10 }}
                    type='flex'
                    justify='start'
                    align='top'
                >
                    <Col>
                        <Text strong>
                            {t('Switch to another account?')}
                            <Link to='/auth/login'>
                                {t('Login')}
                            </Link>
                        </Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default withTranslation()(PasswordResetComponent);
