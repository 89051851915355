// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from 'antd/lib/icon';
import { useTranslation } from 'react-i18next';

import { CursorIcon } from 'icons';
import { ActiveControl } from 'reducers/interfaces';
import { Canvas } from 'cvat-canvas-wrapper';
import { EyesTooltip as Tooltip } from 'utils/custom-antd';

export interface Props {
    canvasInstance: Canvas;
    cursorShortkey: string;
    activeControl: ActiveControl;
}

function CursorControl(props: Props): JSX.Element {
    const {
        canvasInstance,
        activeControl,
        cursorShortkey,
    } = props;
    const { t } = useTranslation();

    return (
        <Tooltip title={t('Cursor {{cursorShortkey}}', { cursorShortkey })} placement='right'>
            <Icon
                component={CursorIcon}
                className={
                    activeControl === ActiveControl.CURSOR ?
                        'cvat-active-canvas-control cvat-cursor-control' :
                        'cvat-cursor-control'
                }
                onClick={
                    activeControl !== ActiveControl.CURSOR ?
                        (): void => canvasInstance.cancel() :
                        undefined
                }
            />
        </Tooltip>
    );
}

export default React.memo(CursorControl);
