import React from 'react';
import { connect } from 'react-redux';
import { verifyAsync, authActions, revertEmailAsync } from 'actions/auth-actions';
import VerificationModalComponent from 'components/settings-page/verification-modal';
import { CombinedState } from 'reducers/interfaces';


interface StateToProps {
    fetching: boolean;
    verifying: boolean;
    oldEmail: string;
    newEmail: string;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const { auth } = state;
    return {
        fetching: auth.fetching,
        verifying: auth.verifying,
        oldEmail: auth.oldEmail,
        newEmail: auth.newEmail,
    };
}


function mapDispatchToProps(dispatch: any): any {
    return {
        onVerify: (oldEmail: string, newEmail: string, code: string): void => dispatch(verifyAsync(oldEmail, newEmail, code)),
        onCancel: (oldEmail: string): void => {
            dispatch(revertEmailAsync(oldEmail));
            dispatch(authActions.hideVerificationModal());
        },
    };
}

function VerificationModalContainer(props: any): JSX.Element {
    return (
        <VerificationModalComponent {...props} />
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(VerificationModalContainer);
