// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import Spin from 'antd/lib/spin';
import Result from 'antd/lib/result';

import DetailsContainer from 'containers/task-page/details';
import JobListContainer from 'containers/task-page/job-list';
import TrainingContainer from 'containers/task-page/training';
import TaskModelsListContainer from 'containers/task-page/models-list';
import ModelRunnerModalContainer from 'containers/model-runner-dialog/model-runner-dialog';
import { Task, Model, ModelStatus } from 'reducers/interfaces';
import TopBarComponent from './top-bar';

interface TaskPageComponentProps {
    task: Task | null | undefined;
    models: Model[];
    filteredModels: Model[];
    jobStatus: ModelStatus;
    fetching: boolean;
    deleteActivity: boolean | null;
    installedGit: boolean;
    auth: boolean;
    authFetching: boolean;
    getTask: () => void;
}

type Props = TaskPageComponentProps & RouteComponentProps<{ id: string }>;

class TaskPageComponent extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);

        const {
            task,
            fetching,
            getTask,
        } = props;

        if (task === null) {
            if (!fetching) {
                getTask();
            }
        }
    }

    public componentDidUpdate(): void {
        const {
            task,
            deleteActivity,
            history,
            fetching,
            auth,
            authFetching,
            getTask,
        } = this.props;

        if (!auth || authFetching) return;

        if (deleteActivity) {
            history.replace('/tasks');
        }

        if (task === null) {
            if (!fetching) {
                getTask();
            }
        }
    }

    public render(): JSX.Element {
        const {
            task,
            models,
            filteredModels,
            jobStatus,
            auth,
            authFetching,
        } = this.props;

        if (!auth || authFetching) {
            return <></>;
        }

        if (task === null) {
            return (
                <Spin size='large' className='cvat-spinner' />
            );
        }

        if (typeof (task) === 'undefined') {
            return (
                <Result
                    className='cvat-not-found'
                    status='404'
                    title='Sorry, but this task was not found'
                    subTitle='Please, be sure information you tried to get exist and you have access'
                />
            );
        }

        return (
            <>
                <Row type='flex' justify='center' align='top' className='cvat-task-details-wrapper'>
                    <Col md={22} lg={18} xl={16} xxl={14}>
                        <TopBarComponent taskInstance={(task as Task).instance} />
                        <DetailsContainer task={(task as Task)} />
                        <JobListContainer task={(task as Task)} />
                        <TrainingContainer
                            taskId={(task as Task).instance.id}
                            models={models}
                            jobStatus={jobStatus}
                        />
                        <TaskModelsListContainer
                            taskId={task.instance.id}
                            models={filteredModels}
                        />
                    </Col>
                </Row>
                <ModelRunnerModalContainer />
            </>
        );
    }
}

export default withRouter(TaskPageComponent);
