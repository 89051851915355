// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';

import ModelsPageComponent from 'components/models-page/models-page';
import {
    Model,
    ModelsQuery,
    CombinedState,
} from 'reducers/interfaces';
import {
    getModelsAsync,
    deleteModelAsync, 
} from 'actions/models-actions';
import { getAboutAsync } from 'actions/about-actions';

interface StateToProps {
    installedAutoAnnotation: boolean;
    installedTFAnnotation: boolean;
    installedTFSegmentation: boolean;
    installedTFTrain: boolean;
    modelsInitialized: boolean;
    modelsFetching: boolean;
    models: Model[];
    gettingQuery: ModelsQuery;
    registeredUsers: any[];
}

interface DispatchToProps {
    onGetModels(gettingQuery: ModelsQuery): void;
    deleteModel(id: number): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const { list } = state.plugins;
    const { models } = state;

    return {
        installedAutoAnnotation: list.AUTO_ANNOTATION,
        installedTFAnnotation: list.TF_ANNOTATION,
        installedTFSegmentation: list.TF_SEGMENTATION,
        installedTFTrain: list.TF_TRAIN,
        modelsInitialized: models.initialized,
        modelsFetching: models.fetching,
        models: models.models,
        gettingQuery: models.gettingQuery,
        registeredUsers: state.users.users,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onGetModels(query: ModelsQuery): void {
            dispatch(getModelsAsync(query));
        },
        deleteModel(id: number): void {
            dispatch(deleteModelAsync(id));
        },
    };
}

function ModelsPageContainer(props: DispatchToProps & StateToProps): JSX.Element | null {
    const {
        installedAutoAnnotation,
        installedTFSegmentation,
        installedTFAnnotation,
        installedTFTrain,
    } = props;

    const render = installedAutoAnnotation
        || installedTFAnnotation
        || installedTFSegmentation
        || installedTFTrain;

    return (
        render ? <ModelsPageComponent {...props} /> : null
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModelsPageContainer);
