// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import 'antd/dist/antd.less';
import '../styles.scss';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Spin from 'antd/lib/spin';
import Layout from 'antd/lib/layout';
import notification from 'antd/lib/notification';
import { withTranslation, WithTranslation } from 'react-i18next';

import store from 'store';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import GlobalErrorBoundary from 'components/global-error-boundary/global-error-boundary';
import ShorcutsDialog from 'components/shortcuts-dialog/shortcuts-dialog';
import SettingsPageContainer from 'containers/settings-page/settings-page';
import TasksPageContainer from 'containers/tasks-page/tasks-page';
import CreateTaskPageContainer from 'containers/create-task-page/create-task-page';

import TaskPageContainer from 'containers/task-page/task-page';
import ModelsPageContainer from 'containers/models-page/models-page';
import CreateModelPageContainer from 'containers/create-model-page/create-model-page';
import AnnotationPageContainer from 'containers/annotation-page/annotation-page';
import LoginPageContainer from 'containers/login-page/login-page';
import RegisterPageContainer from 'containers/register-page/register-page';
import HeaderContainer from 'containers/header/header';
import SiderContainer from 'containers/header/sider';
// import FooterComponent from 'components/footer/footer';
import { customWaViewHit } from 'utils/enviroment';
import { formatStringWithTranslation } from 'utils/strings';

import getCore from 'cvat-core-wrapper';
import {
    NotificationsState,
    ModelStatus, // Humanome Eyes
    JobsStatus, // Humanome Eyes
} from 'reducers/interfaces';

// Humanome Eyes
// import TwoFactorContainer from 'containers/two-factor';
import PasswordResetContainer from 'containers/password-reset';
import NewPasswordContainer from 'containers/new-password';
import TrainedModelPageContainer from 'containers/trained-model-page/trained-model-page';
import AdminPageContainer from 'containers/admin-page/admin-page';

interface CVATAppProps extends WithTranslation {
    loadFormats: () => void;
    loadUsers: () => void;
    loadAbout: () => void;
    verifyAuthorized: () => void;
    loadUserAgreements: () => void;
    initPlugins: () => void;
    resetErrors: () => void;
    resetMessages: () => void;
    switchShortcutsDialog: () => void;
    loadModels: () => void; // Humanome Eyes
    checkStatus: (timeout: number, force: boolean) => void; // Humanome Eyes
    keyMap: KeyMap;
    userInitialized: boolean;
    userFetching: boolean;
    pluginsInitialized: boolean;
    pluginsFetching: boolean;
    formatsInitialized: boolean;
    formatsFetching: boolean;
    usersInitialized: boolean;
    usersFetching: boolean;
    aboutInitialized: boolean;
    aboutFetching: boolean;
    aboutVersion: string;
    aboutLastCheckDate: Date;
    modelsInitialized: boolean; // Humanome Eyes
    modelsFetching: boolean; // Humanome Eyes
    trainingJobs: JobsStatus; // Humanome Eyes
    checkingStatus: boolean; // Humanome Eyes
    installedAutoAnnotation: boolean;
    installedTFTrain: boolean;
    installedTFAnnotation: boolean;
    installedTFSegmentation: boolean;
    userAgreementsFetching: boolean;
    userAgreementsInitialized: boolean;
    notifications: NotificationsState;
    user: any;
    redirectTo: string;
}

class CVATApplication extends React.PureComponent<CVATAppProps & RouteComponentProps> {
    checkingStatusLock = false;
    statusChecker: NodeJS.Timer | null = null;

    // constructor(props: CVATAppProps & RouteComponentProps) {
    //     super(props);
    // }

    public componentDidMount(): void {
        const core = getCore();
        const { verifyAuthorized, history, location } = this.props;
        // configure({ ignoreRepeatedEventsWhenKeyHeldDown: false });

        // Logger configuration
        const userActivityCallback: (() => void)[] = [];
        window.addEventListener('click', () => {
            userActivityCallback.forEach((handler) => handler());
        });
        core.logger.configure(() => window.document.hasFocus, userActivityCallback);

        customWaViewHit(location.pathname, location.search, location.hash);
        history.listen((_location) => {
            customWaViewHit(_location.pathname, _location.search, _location.hash);
            notification.close('eyes.notification.message');
        });

        verifyAuthorized();
    }

    public componentDidUpdate(): void {
        const {
            verifyAuthorized,
            loadFormats,
            loadUsers,
            loadAbout,
            loadUserAgreements,
            initPlugins,
            loadModels,
            userInitialized,
            userFetching,
            formatsInitialized,
            formatsFetching,
            usersInitialized,
            usersFetching,
            // aboutInitialized,
            aboutFetching,
            aboutVersion,
            aboutLastCheckDate,
            pluginsInitialized,
            pluginsFetching,
            user,
            userAgreementsFetching,
            userAgreementsInitialized,
            modelsInitialized,
            modelsFetching,
        } = this.props;

        this.showErrors();
        this.showMessages();

        if (!userInitialized && !userFetching) {
            verifyAuthorized();
            return;
        }

        if (!userAgreementsInitialized && !userAgreementsFetching) {
            loadUserAgreements();
            return;
        }

        if (user == null) {
            return;
        }

        if (!formatsInitialized && !formatsFetching) {
            loadFormats();
        }

        if (!usersInitialized && !usersFetching) {
            loadUsers();
        }

        const date = new Date();
        date.setMinutes(date.getMinutes() - 1);
        if ((!aboutVersion || date > aboutLastCheckDate) && !aboutFetching && !userFetching) {
            // eslint-disable-next-line no-console
            console.debug(`Check version (last time: ${aboutLastCheckDate}, version: ${aboutVersion})`);
            loadAbout();
        }

        const localVersion = store.get('eyesVersion');
        if (!localVersion) {
            store.set('eyesVersion', aboutVersion);
            store.set('reloading', false);
        } else if (localVersion !== aboutVersion) {
            if (!store.get('reloading')) {
                store.set('reloading', true);
                store.set('eyesVersion', aboutVersion);
                window.location.reload();
            }
        } else {
            store.set('reloading', false);
        }

        if (!pluginsInitialized && !pluginsFetching) {
            initPlugins();
        }

        if (pluginsInitialized && !modelsInitialized && !modelsFetching) {
            loadModels();
        }

        if (modelsInitialized && !this.checkingStatusLock) {
            this.checkingStatusLock = true;
            this.checkJobsStatus();
        }
    }

    public componentWillUnmount(): void {
        if (this.statusChecker !== null) clearTimeout(this.statusChecker);
    }

    private showMessages(): void {
        function showMessage(title: string): void {
            notification.info({
                message: (
                    <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                ),
                duration: null,
                key: 'eyes.notification.message',
            });
        }

        const {
            notifications,
            resetMessages,
            t,
        } = this.props;

        let shown = false;
        for (const where of Object.keys(notifications.messages)) {
            for (const what of Object.keys(notifications.messages[where])) {
                const message = notifications.messages[where][what];
                shown = shown || !!message;
                if (message) {
                    // message strings come from reducers/notifications-reducer.ts
                    showMessage(formatStringWithTranslation(t, message));
                }
            }
        }

        if (shown) {
            resetMessages();
        }
    }

    private showErrors(): void {
        function showError(title: string, _error: any, className?: string): void {
            const error = _error.toString();
            const dynamicProps = typeof className === 'undefined' ? {} : { className };
            notification.error({
                ...dynamicProps,
                message: (
                    <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                ),
                duration: null,
                description: error.length > 200 ? 'Open the Browser Console to get details' : error,
            });

            // eslint-disable-next-line no-console
            console.error(error);
        }

        const {
            notifications,
            resetErrors,
            t,
        } = this.props;

        let shown = false;
        for (const where of Object.keys(notifications.errors)) {
            for (const what of Object.keys(notifications.errors[where])) {
                const error = notifications.errors[where][what];
                shown = shown || !!error;
                if (error) {
                    // error.message, error.reason strings come from reducers/notifications-reducer.ts
                    showError(
                        formatStringWithTranslation(t, error.message),
                        formatStringWithTranslation(t, error.reason),
                        error.className,
                    );
                }
            }
        }

        if (shown) {
            resetErrors();
        }
    }

    // Humanome Eyes
    private checkJobsStatus(): void {
        const {
            trainingJobs,
            checkStatus,
        } = this.props;

        if (Object.keys(trainingJobs).length === 0) {
            this.checkingStatusLock = false;
            return;
        }

        // get shortest interval
        let latestTime = new Date(0).getTime();
        Object.keys(trainingJobs).forEach((tid) => {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { start_time, status } = trainingJobs[Number(tid)];
            const startTime = Date.parse(start_time);
            if ([ModelStatus.PREPARING, ModelStatus.STOPPING].includes(status)) {
                latestTime = Date.now();
            } else if (startTime > latestTime) {
                latestTime = startTime;
            }
        });
        if (latestTime > 0) {
            let timeout = 0;
            const elapsedMinutes = Math.floor((Date.now() - latestTime) / 60000);
            if (elapsedMinutes < 1) timeout = 5 * 1000; // 2 seconds
            else if (elapsedMinutes <= 10) timeout = 10 * 1000; // 10 seconds
            else if (elapsedMinutes <= 60) timeout = 60 * 1000; // 1 minutes
            else timeout = 300 * 1000; // 5 minutes
            if (timeout > 0) {
                this.statusChecker = setTimeout(() => {
                    checkStatus(0, false);
                    this.checkingStatusLock = false;
                }, timeout);
            } else {
                this.checkingStatusLock = false;
            }
        } else {
            this.checkingStatusLock = false;
        }
    }

    // Where you go depends on your URL
    public render(): JSX.Element {
        const {
            userInitialized,
            usersInitialized,
            // aboutInitialized,
            pluginsInitialized,
            formatsInitialized,
            installedAutoAnnotation,
            installedTFSegmentation,
            installedTFAnnotation,
            switchShortcutsDialog,
            user,
            history,
            keyMap,
            redirectTo, // Humanome Eyes
        } = this.props;

        const readyForRender = (userInitialized && user == null) ||
            (userInitialized && formatsInitialized && pluginsInitialized && usersInitialized);

        const withModels = installedAutoAnnotation || installedTFAnnotation || installedTFSegmentation;

        const subKeyMap = {
            SWITCH_SHORTCUTS: keyMap.SWITCH_SHORTCUTS,
            OPEN_SETTINGS: keyMap.OPEN_SETTINGS,
        };

        const handlers = {
            SWITCH_SHORTCUTS: (event: KeyboardEvent | undefined) => {
                if (event) {
                    event.preventDefault();
                }

                switchShortcutsDialog();
            },
            OPEN_SETTINGS: (event: KeyboardEvent | undefined) => {
                if (event) {
                    event.preventDefault();
                }

                if (history.location.pathname.endsWith('settings')) {
                    history.goBack();
                } else {
                    history.push('/settings');
                }
            },
        };

        if (readyForRender) {
            if (user) {
                return (
                    <GlobalErrorBoundary>
                        <Layout>
                            <HeaderContainer />
                            <Layout>
                                <SiderContainer />
                                <Layout.Content>
                                    <ShorcutsDialog />
                                    <GlobalHotKeys keyMap={subKeyMap} handlers={handlers}>
                                        <Switch>
                                            <Route exact path='/settings' component={SettingsPageContainer} />
                                            <Route exact path='/tasks' component={TasksPageContainer} />
                                            <Route exact path='/tasks/create' component={CreateTaskPageContainer} />
                                            <Route exact path='/tasks/:id' component={TaskPageContainer} />
                                            <Route exact path='/tasks/:tid/jobs/:jid' component={AnnotationPageContainer} />
                                            {withModels && <Route exact path='/models' component={ModelsPageContainer} />}
                                            {withModels && <Route exact path='/models/:mid' component={TrainedModelPageContainer} />}
                                            {installedAutoAnnotation && <Route exact path='/models/create' component={CreateModelPageContainer} />}
                                            {user.isStaff && <Route exact path='/user_manage' component={AdminPageContainer} />}
                                            <Redirect push to='/tasks' />
                                        </Switch>
                                    </GlobalHotKeys>
                                    {/* eslint-disable-next-line */}
                                    <a id='downloadAnchor' style={{ display: 'none' }} download />
                                </Layout.Content>
                            </Layout>
                        </Layout>
                    </GlobalErrorBoundary>
                );
            }

            const afterRegistration = store.get('afterRegistration');
            // const cognitoUser = store.get('cognitoUsername');

            return (
                <GlobalErrorBoundary>
                    <Layout>
                        <Switch>
                            { afterRegistration && <Redirect to='/auth/login' from='/auth/register' />}
                            {/* { !cognitoUser && <Redirect to='/auth/login' from='/auth/two-factor' />} */}
                            <Route exact path='/auth/password-reset' component={PasswordResetContainer} />
                            {/* <Route exact path='/auth/two-factor' component={TwoFactorContainer} /> */}
                            <Route exact path='/auth/register' component={RegisterPageContainer} />
                            <Route exact path='/auth/login' component={LoginPageContainer} />
                            { redirectTo && <Redirect to={redirectTo} />}
                            <Route exact path='/auth/new-password' component={NewPasswordContainer} />
                            <Redirect to='/auth/login' />
                        </Switch>
                    </Layout>
                </GlobalErrorBoundary>
            );
        }

        return (
            <Spin size='large' className='cvat-spinner' />
        );
    }
}

export default withRouter(withTranslation()(CVATApplication));
