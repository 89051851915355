// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Input from 'antd/lib/input';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import { Translation } from 'react-i18next';

export interface BaseConfiguration {
    name: string;
}

type Props = FormComponentProps & {
    onSubmit(values: BaseConfiguration): void;
};

class BasicConfigurationForm extends React.PureComponent<Props> {
    private namelInput = React.createRef<Input>();

    public componentDidMount(): void {
        this.namelInput.current?.focus();
    }

    public submit(): Promise<void> {
        return new Promise((resolve, reject) => {
            const {
                form,
                onSubmit,
            } = this.props;

            form.validateFields((error, values): void => {
                if (!error) {
                    onSubmit({
                        name: values.name,
                    });
                    resolve();
                } else {
                    reject();
                }
            });
        });
    }

    public resetFields(): void {
        const { form } = this.props;
        form.resetFields();
    }

    public render(): JSX.Element {
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Translation>
                {
                    (t) => (
                        <Form onSubmit={(e: React.FormEvent): void => e.preventDefault()}>
                            <Form.Item hasFeedback label={<span>{t('Name')}</span>}>
                                { getFieldDecorator('name', {
                                    rules: [{
                                        required: true,
                                        message: 'Please, specify a name',
                                    }],
                                })(
                                    <Input
                                        ref={this.namelInput}
                                    />,
                                ) }
                            </Form.Item>
                        </Form>
                    )
                }
            </Translation>
        );
    }
}

export default Form.create<Props>()(BasicConfigurationForm);
