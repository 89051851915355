// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from 'antd/lib/icon';
import { useTranslation } from 'react-i18next';

import { FitIcon } from 'icons';
import { Canvas } from 'cvat-canvas-wrapper';
import { EyesTooltip as Tooltip } from 'utils/custom-antd';

export interface Props {
    canvasInstance: Canvas;
}

function FitControl(props: Props): JSX.Element {
    const {
        canvasInstance,
    } = props;
    const { t } = useTranslation();

    return (
        <Tooltip title={t('Fit the image [Double Click]')} placement='right'>
            <Icon className='cvat-fit-control' component={FitIcon} onClick={(): void => canvasInstance.fit()} />
        </Tooltip>
    );
}

export default React.memo(FitControl);
