// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export interface LoginData {
    username: string;
    password: string;
}

type LoginFormProps = {
    fetching: boolean;
    onSubmit(loginData: LoginData): void;
    t: TFunction;
} & FormComponentProps & WithTranslation;

class LoginFormComponent extends React.PureComponent<LoginFormProps> {
    private emailInput = React.createRef<Input>();
    private passwordInput = React.createRef<Input>();

    public componentDidMount(): void {
        this.emailInput.current?.focus();
    }

    private handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        const {
            form,
            onSubmit,
        } = this.props;

        form.validateFields((error, values): void => {
            if (!error) {
                onSubmit(values);
                this.passwordInput.current?.select();
            } else if (!this.emailInput.current?.input.value) {
                this.emailInput.current?.focus();
            } else {
                this.passwordInput.current?.focus();
            }
        });
    };

    private renderUsernameField(): JSX.Element {
        const { form } = this.props;
        const { t } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Form.Item hasFeedback>
                {getFieldDecorator('username', {
                    rules: [{
                        required: true,
                        message: t('Please specify your Email address'),
                    }],
                })(
                    <Input
                        autoComplete='email'
                        prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Email address')}
                        type='email'
                        ref={this.emailInput}
                        onPressEnter={(e) => {
                            if (this.emailInput.current?.input.value && !this.passwordInput.current?.input.value) {
                                this.passwordInput.current?.focus();
                                e.preventDefault();
                            }
                        }}
                    />,
                )}
            </Form.Item>
        );
    }

    private renderPasswordField(): JSX.Element {
        const { form } = this.props;
        const { t } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Form.Item hasFeedback>
                {getFieldDecorator('password', {
                    rules: [{
                        required: true,
                        message: t('Please specify a password'),
                    }],
                })(
                    <Input.Password
                        autoComplete='current-password'
                        prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Password')}
                        type='password'
                        ref={this.passwordInput}
                    />,
                )}
            </Form.Item>
        );
    }

    public render(): JSX.Element {
        const { fetching } = this.props;
        const { t } = this.props;
        return (
            <Form onSubmit={this.handleSubmit} className='login-form'>
                {this.renderUsernameField()}
                {this.renderPasswordField()}

                <Form.Item>
                    <Button
                        type='primary'
                        loading={fetching}
                        disabled={fetching}
                        htmlType='submit'
                        className='login-form-button'
                    >
                        {t('Sign in')}
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default withTranslation()(Form.create<LoginFormProps>()(LoginFormComponent));
