// Copyright (C) 2020 Humanome Lab.

import React from 'react';

import {
    Row,
    Col,
} from 'antd';
import Pagination from 'antd/lib/pagination';

import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import { Model } from 'reducers/interfaces';
import TrainedModelItem from 'containers/models-page/trained-model-item';

interface TrainedModelsProps {
    onSwitchPage(page: number): void;
    models: Model[];
    pageSize: number;
    currentPage: number;
    numberOfModels: number;
}

export default function TrainedModelsListComponent(props: TrainedModelsProps): JSX.Element {
    const {
        models,
        pageSize,
        currentPage,
        numberOfModels,
        onSwitchPage,
    } = props;

    const { t } = useTranslation();
    const items = models.filter((model): boolean => model.type === 'trained').map(
        (model): JSX.Element => {
            if (model.id) {
                return (
                    <TrainedModelItem model={model} key={model.id} />
                );
            }
            return <></>;
        },
    );

    return (
        <>
            <Row type='flex' justify='center' align='middle'>
                <Col xs={23} sm={22} md={22} lg={18} xl={16} xxl={14} className='cvat-models-list'>
                    <Row type='flex' justify='start' align='middle'>
                        <Col>
                            <Text className='cvat-text-color' strong>{t('Your models')}</Text>
                        </Col>
                    </Row>
                    <Row type='flex' justify='space-between' align='middle' style={{ padding: '10px' }}>
                        <Col span={4}>
                            <Text strong>{t('Status')}</Text>
                        </Col>
                        <Col span={10}>
                            <Text strong>{t('Name')}</Text>
                        </Col>
                        <Col span={5}>
                            <Text strong>{t('Trained')}</Text>
                        </Col>
                        <Col span={5}>
                            <Text strong>{t('Labels')}</Text>
                        </Col>
                    </Row>
                    { items }
                </Col>
            </Row>
            <Row type='flex' justify='center' align='middle'>
                <Col xs={23} sm={22} md={22} lg={18} xl={16} xxl={14}>
                    <Pagination
                        className='cvat-tasks-pagination'
                        onChange={onSwitchPage}
                        total={numberOfModels}
                        pageSize={pageSize}
                        current={currentPage}
                        showQuickJumper
                    />
                </Col>
            </Row>
        </>
    );
}
