// Copyright (C) 2020 Humanome Lab., Inc.
//

import './styles.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Spin, Row, Col } from 'antd';
import Text from 'antd/lib/typography/Text';
import EditableFormTable from './users-table';

interface Props extends WithTranslation {
    fetching: boolean;
    users: any[];
    getUsers: () => void;
    activateUser: (cognitoId: string) => void;
    deactivateUser: (cognitoId: string) => void;
    updateUser: (username: string, attributes: any[]) => void;
    t: TFunction;
}

class AdminPageComponent extends React.Component<Props & RouteComponentProps> {
    public componentDidMount(): void {
        const { getUsers } = this.props;
        getUsers();
    }

    public render(): JSX.Element {
        const {
            fetching,
            users,
            activateUser,
            deactivateUser,
            updateUser,
            t,
        } = this.props;

        return (
            <div className='eyes-admin-page'>
                <Row type='flex' justify='center' align='top'>
                    <Col span={22}>
                        <Text className='cvat-title'>{t('User Management Page')}</Text>
                    </Col>
                </Row>
                <Row type='flex' justify='center' align='middle'>
                    <Col span={22}>
                        { fetching ? (
                            <Spin size='large' className='cvat-spinner' />
                        ) : (
                            <EditableFormTable
                                data={users}
                                activateUser={activateUser}
                                deactivateUser={deactivateUser}
                                updateUser={updateUser}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(withTranslation()(AdminPageComponent));
