/* eslint-disable jsx-a11y/anchor-is-valid */
// Copyright (C) 2020 Humanome Lab., Inc.
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
// import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import { useTranslation } from 'react-i18next';
import Icon from 'antd/lib/icon';
import store from 'store'; // Humanome Eyes
import { changeLanguage } from 'i18n';
import { EyesLogo } from 'icons';

import LoginForm, { LoginData } from './login-form';
// import CookieDrawer from './cookie-policy-drawer';

interface LoginPageComponentProps {
    fetching: boolean;
    onLogin: (username: string, password: string) => void;
}

function LoginPageComponent(props: LoginPageComponentProps & RouteComponentProps): JSX.Element {
    const sizes = {
        xs: { span: 16 },
        sm: { span: 12 },
        md: { span: 10 },
        lg: { span: 8 },
        xl: { span: 8 },
    };

    const {
        fetching,
        onLogin,
    } = props;

    const { t } = useTranslation();

    store.set('afterRegistration', false); // Humanome Eyes

    return (
        <div className='cvat-login-page'>
            <Row style={{ marginTop: 10 }} type='flex' justify='center' align='middle'>
                <Col {...sizes}>
                    <Row className='cvat-login-logo'>
                        <a
                            href='https://humanome.jp/activities/eyes/'
                        >
                            <Icon className='cvat-login-logo-icon' component={EyesLogo} />
                        </a>
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                        <Text strong>{t('Login')}</Text>
                        <LoginForm
                            fetching={fetching}
                            onSubmit={(loginData: LoginData): void => {
                                onLogin(loginData.username, loginData.password);
                            }}
                        />
                    </Row>
                    <Row style={{ marginTop: 10 }} type='flex' justify='start' align='top'>
                        <Col>
                            <Text>
                                {t('Change Language')}
                                : &nbsp;
                                <Link to='#' className='lang_en' onClick={() => changeLanguage('en')}>English</Link>
                                &nbsp;|&nbsp;
                                <Link to='#' className='lang_ja' onClick={() => changeLanguage('ja')}>日本語</Link>
                                <br />
                                (
                                {t('Explain After login')}
                                )
                            </Text>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }} type='flex' justify='start' align='top'>
                        <Col>
                            <Text strong>
                                {t('New to Humanome Eyes? Create')}
                                <Link to='/auth/register'>
                                    &nbsp;
                                    {t('an account')}
                                </Link>
                            </Text>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }} type='flex' justify='start' align='top'>
                        <Col>
                            <Text strong>
                                <a
                                    href='https://humanome.jp/activities/eyes/beginner/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {t('Here is a simple manual for Humanome Eyes')}
                                </a>
                            </Text>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }} type='flex' justify='start' align='top'>
                        <Col>
                            <Text strong>
                                <a href='https://humanome.jp/contact/eyes-request/' target='_blank' rel='noopener noreferrer'>
                                    {t('Please click here to ask us more detail about Humanome Eyes')}
                                </a>
                            </Text>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }} type='flex' justify='start' align='top'>
                        <Col>
                            <Text strong>
                                <Link to='/auth/password-reset'>{t('Forgot password?')}</Link>
                            </Text>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }} type='flex' justify='start' align='top'>
                        <Col>
                            <Text>
                                <a href='https://humanome.jp/' target='_blank' rel='noopener noreferrer'>Humanome Lab., Inc.</a>
                                &nbsp;© 2020
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <CookieDrawer /> */}
        </div>
    );
}

export default withRouter(LoginPageComponent);
