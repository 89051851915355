import React from 'react';
import { connect } from 'react-redux';

import {
    updateUserAttributes
} from 'actions/auth-actions';

import { CombinedState } from 'reducers/interfaces';

import UpgradeComponent from 'components/settings-page/upgrade';

interface StateToProps {
    user: any;
}

interface DispatchToProps {
}

function mapStateToProps(state: CombinedState): StateToProps {
    const user = state.auth.user;

    return {
        user
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
    };
}

function UpgradeContainer(props: StateToProps & DispatchToProps): JSX.Element {
    return (
        <div className='cvat-profile-settings'>
            <UpgradeComponent {...props} />
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpgradeContainer);
