// Copyright (C) 2020 Humanome Lab., Inc.
//

import { ActionUnion, createAction, ThunkAction } from 'utils/redux';
import { HAS_EXPORT_MODEL_PERM_GROUP } from 'containers/trained-model-page/trained-model-page';
import getCore from 'cvat-core-wrapper';

const core = getCore();
const baseURL = core.config.backendAPI.slice(0, -7);

export enum AdminActionTypes{
    GET_COGNITO_USERS = 'GET_COGNITO_USERS',
    GET_COGNITO_USERS_SUCCESS = 'GET_COGNITO_USERS_SUCCESS',
    GET_COGNITO_USERS_FAILED = 'GET_COGNITO_USERS_FAILED',
    ACTIVATE_USER = 'ACTIVATE_USER',
    ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS',
    ACTIVATE_USER_FAILED = 'ACTIVATE_USER_FAILED',
    DEACTIVATE_USER = 'DEACTIVATE_USER',
    DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS',
    DEACTIVATE_USER_FAILED = 'DEACTIVATE_USER_FAILED',
    UPDATE_USER = 'UPDATE_USER',
    UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILED = 'UPDATE_USER_FAILED',
}

export const adminActions = {
    getUsers: () => createAction(AdminActionTypes.GET_COGNITO_USERS),
    getUsersSuccess: (users: any[]) => createAction(AdminActionTypes.GET_COGNITO_USERS_SUCCESS, { users }),
    getUsersFailed: (error: any) => createAction(AdminActionTypes.GET_COGNITO_USERS_FAILED, { error }),
    activateUser: () => createAction(AdminActionTypes.ACTIVATE_USER),
    activateUserSuccess: () => createAction(AdminActionTypes.ACTIVATE_USER_SUCCESS),
    activateUserFailed: (error: any) => createAction(AdminActionTypes.ACTIVATE_USER_FAILED, { error }),
    deactivateUser: () => createAction(AdminActionTypes.DEACTIVATE_USER),
    deactivateUserSuccess: () => createAction(AdminActionTypes.DEACTIVATE_USER_SUCCESS),
    deactivateUserFailed: (error: any) => createAction(AdminActionTypes.DEACTIVATE_USER_FAILED, { error }),
    updateUser: () => createAction(AdminActionTypes.UPDATE_USER),
    updateUserSuccess: () => createAction(AdminActionTypes.UPDATE_USER_SUCCESS),
    updateUserFailed: (error: any) => createAction(AdminActionTypes.UPDATE_USER_FAILED, { error }),
};

export type AdminAction = ActionUnion<typeof adminActions>;

async function getUsers(): Promise<any[]> {
    const response = await core.server.request(
        `${baseURL}/user_manage/users`, { method: 'GET' },
    );

    const users = response.data.reduce((acc: any[], user: any) => {
        acc.push({
            cognito_id: user.Username,
            enabled: user.Enabled,
            user_status: user.UserStatus,
            ...user.Attributes.reduce((a: any, v: any) => ({ ...a, [v.Name]: v.Value }), {}),
            created_date: user.UserCreateDate,
            modified_date: user.UserLastModifiedDate,
            billable_time: user.billable_time,
            is_staff: user.is_staff,
            export_model: user.groups.indexOf(HAS_EXPORT_MODEL_PERM_GROUP) >= 0 ? 'yes' : null,
            groups: user.groups,
        });
        return acc;
    }, []);
    return users;
}

async function activate(cognitoId: string): Promise<void> {
    await core.server.request(
        `${baseURL}/user_manage/activate`, {
            method: 'POST',
            data: { cognitoId },
        },
    );
}

async function deactivate(cognitoId: string): Promise<void> {
    await core.server.request(
        `${baseURL}/user_manage/deactivate`, {
            method: 'POST',
            data: { cognitoId },
        },
    );
}

async function updateUserAttribute(cognitoId: string, attributes: any[]): Promise<void> {
    await core.server.request(
        `${baseURL}/user_manage/update`, {
            method: 'POST',
            data: { cognitoId, attributes },
        },
    );
}

export function getUsersAsync(): ThunkAction {
    return async (dispatch) => {
        dispatch(adminActions.getUsers());
        try {
            const users = await getUsers();
            dispatch(adminActions.getUsersSuccess(users));
        } catch (error) {
            dispatch(adminActions.getUsersFailed(error));
        }
    };
}

export function activateUserAsync(cognitoId: string): ThunkAction {
    return async (dispatch) => {
        dispatch(adminActions.activateUser());
        try {
            await activate(cognitoId);
            dispatch(adminActions.activateUserSuccess());
            dispatch(adminActions.getUsers());
            try {
                const users = await getUsers();
                dispatch(adminActions.getUsersSuccess(users));
            } catch (error) {
                dispatch(adminActions.getUsersFailed(error));
            }
        } catch (error) {
            dispatch(adminActions.activateUserFailed(error));
        }
    };
}

export function deactivateUserAsync(cognitoId: string): ThunkAction {
    return async (dispatch) => {
        dispatch(adminActions.deactivateUser());
        try {
            await deactivate(cognitoId);
            dispatch(adminActions.deactivateUserSuccess());
            dispatch(adminActions.getUsers());
            try {
                const users = await getUsers();
                dispatch(adminActions.getUsersSuccess(users));
            } catch (error) {
                dispatch(adminActions.getUsersFailed(error));
            }
        } catch (error) {
            dispatch(adminActions.deactivateUserFailed(error));
        }
    };
}

export function updateUserAsync(username: string, attributes: any[]): ThunkAction {
    return async (dispatch) => {
        dispatch(adminActions.updateUser());
        try {
            await updateUserAttribute(username, attributes);
            dispatch(adminActions.updateUserSuccess());
            dispatch(adminActions.getUsers());
            try {
                const users = await getUsers();
                dispatch(adminActions.getUsersSuccess(users));
            } catch (error) {
                dispatch(adminActions.getUsersFailed(error));
            }
        } catch (error) {
            dispatch(adminActions.updateUserFailed(error));
        }
    };
}
