/* eslint-disable import/prefer-default-export */
// Copyright (C) 2022 Humanome Lab., Inc.
//
import { TFunction } from 'i18next';

export function formatStringWithTranslation(
    t: TFunction,
    message: string | [string, { [name:string]: any }],
): string {
    if (typeof message === 'string') {
        return t(message);
    }
    return t(...message);
}
