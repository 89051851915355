/* eslint-disable @typescript-eslint/naming-convention */
// Copyright (C) 2020 Humanome Lab., Inc.
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Form, { FormComponentProps } from 'antd/lib/form';
import {
    Button,
    Checkbox,
    Input,
    InputNumber,
    Modal,
    Alert,
    Collapse,
    Select,
    Spin,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import getCore from 'cvat-core-wrapper';
import { ModelStatus, Model } from 'reducers/interfaces';

const core = getCore();
const baseURL = core.config.backendAPI.slice(0, -7);

interface Schedule {
    step: number;
    learning_rate: number;
}

interface AdvancedConfigRCNN {
    first_stage_features_stride: number;
    schedules: Schedule[];
    momentum_optimizer_value: number;
    random_horizontal_flip: boolean;
    random_vertical_flip: boolean;
}

interface AdvancedConfigSSD {
    initial_learning_rate: number,
    decay_steps: number,
    decay_factor: number,
    momentum_optimizer_value: number,
    decay: number,
    epsilon: number,
    random_horizontal_flip: boolean;
    random_vertical_flip: boolean;
}

export interface TrainingData {
    steps: number;
    lr: number;
    early_stopping: boolean;
    patience: number;

    advanced_config: AdvancedConfigRCNN | AdvancedConfigSSD;
}

type Props = {
    taskId: number;
    models: Model[];
    loaded: boolean;
    jobStatus: ModelStatus;
    modelsInitialized: boolean;
    statusInitialized: boolean;
    checkingStatus: boolean;
    plan: string;
    onStartTraining: (trainingData: TrainingData) => void;
    onTerminateTraining(): void;
    checkJobsStatus: (timeout: number) => void;
    t: TFunction;
} & FormComponentProps & WithTranslation;

interface State {
    originModelName: string;
    steps: number;
    lr: number;
    early_stopping: boolean;
    patience: number;
    advanced_config: AdvancedConfigRCNN | AdvancedConfigSSD;
}

const defaultConfig: { [key: string]: TrainingData } = {
    faster_rcnn_resnet101_coco: {
        steps: 100,
        lr: 0.0001,
        early_stopping: false,
        patience: 100,

        advanced_config: {
            first_stage_features_stride: 16,
            schedules: [
                { step: 5000, learning_rate: 0.00001 },
                { step: 10000, learning_rate: 0.000001 },
            ],
            momentum_optimizer_value: 0.9,
            random_horizontal_flip: true,
            random_vertical_flip: true,
        },
    },
    ssd_mobilenet_v2_coco: {
        steps: 100,
        lr: 0.00400000018999,
        early_stopping: false,
        patience: 100,

        advanced_config: {
            initial_learning_rate: 0.00400000018999,
            decay_steps: 800720,
            decay_factor: 0.949999988079,
            momentum_optimizer_value: 0.899999976158,
            decay: 0.899999976158,
            epsilon: 1.0,
            random_horizontal_flip: true,
            random_vertical_flip: true,
        },
    },
};

const runningStatuses = [
    ModelStatus.PREPARING,
    ModelStatus.QUEUED,
    ModelStatus.PRETRAINING,
    ModelStatus.TRAINING,
    ModelStatus.POSTTRAINING,
    ModelStatus.STOPPING,
];

const cancelableStatuses = [
    ModelStatus.QUEUED,
    ModelStatus.PRETRAINING,
    ModelStatus.TRAINING,
];

// For validate advance configurations
function isPositiveInteger(_: any, value: any, callback: any): void {
    if (!value) {
        callback();
        return;
    }
    const intValue = +value;
    if (Number.isNaN(intValue) ||
        !Number.isInteger(intValue) || intValue < 1) {
        callback('Value must be a positive integer');
    }
    callback();
}
function isPositiveFloat(_: any, value: any, callback: any): void {
    if (!value) {
        callback();
        return;
    }
    const floatValue = parseFloat(value);
    if (Number.isNaN(floatValue) || floatValue <= 0) {
        callback('Value must be a positive value');
    }
    callback();
}
function isFloatRange(min: number, max: number, _: any, value: any, callback: any): void {
    if (!value) {
        callback();
        return;
    }
    const floatValue = parseFloat(value);
    if (Number.isNaN(floatValue) || floatValue < min || floatValue > max) {
        callback(`Value must be an float [${min}, ${max}]`);
    }
    callback();
}
function validateSteps(min: number, max: number, _: any, value: any, callback: any): void {
    if (!value) {
        callback();
        return;
    }
    const intValue = +value;
    if (Number.isNaN(intValue) || !Number.isInteger(intValue) || intValue < min || intValue > max) {
        callback(`Value must be integer from ${min} to ${max}`);
    }
    callback();
}

class TrainingFormComponent extends React.Component<Props, State> {
    // Toggle start button
    conditionInitialzed = false;
    hasInsufficientData = true;
    job_status: ModelStatus = ModelStatus.UNKNOWN;
    isNotStartable = true;
    isNotCancelable = true;
    conditions: any = {};

    constructor(props: Props) {
        super(props);
        this.state = {
            ...defaultConfig.faster_rcnn_resnet101_coco,
            originModelName: 'faster_rcnn_resnet101_coco',
        };
    }

    public componentDidMount(): void {
        const {
            jobStatus,
            modelsInitialized,
            checkJobsStatus,
        } = this.props;
        this.conditionInitialzed = false;
        if (modelsInitialized) {
            checkJobsStatus(0);
            this.checkCondition();
            this.job_status = jobStatus;
            this.updateButtonsStatus();
        }
    }

    public componentDidUpdate(prevProps: Props): void {
        if (prevProps !== this.props) {
            const {
                loaded,
                jobStatus,
                modelsInitialized,
            } = this.props;

            if (!prevProps.modelsInitialized && modelsInitialized) {
                if (prevProps.jobStatus !== ModelStatus.UNKNOWN &&
                    jobStatus === ModelStatus.UNKNOWN) {
                    this.job_status = jobStatus;
                }
            }
            if ((loaded || (!prevProps.modelsInitialized && modelsInitialized)) &&
                (!this.conditionInitialzed || this.hasInsufficientData)) {
                this.checkCondition();
            }

            if (this.job_status === ModelStatus.PREPARING) {
                if (runningStatuses.includes(jobStatus) || jobStatus === ModelStatus.FAILED) {
                    this.job_status = jobStatus;
                }
            } else if (this.job_status === ModelStatus.STOPPING) {
                if (!runningStatuses.includes(jobStatus)) {
                    this.job_status = jobStatus;
                }
            } else {
                this.job_status = jobStatus;
            }
        }
    }

    private handleSubmitTraining = (e: React.FormEvent): void => {
        e.preventDefault();
        const {
            form,
            onStartTraining,
            checkJobsStatus,
            t,
        } = this.props;

        Modal.confirm({
            className: 'eyes-training-form-confirm-start',
            title: t('Start training'),
            content: t('The training will be started. This will take a long time to finish it. Continue?'),
            onOk: () => {
                this.isNotStartable = true;
                this.forceUpdate();
                form.validateFields((error, values): void => {
                    if (!error) {
                        onStartTraining(values);
                        this.job_status = ModelStatus.PREPARING;
                        checkJobsStatus(2000);
                        setTimeout(() => {
                            this.updateButtonsStatus();
                        }, 3000);
                        this.forceUpdate();
                    } else this.isNotStartable = false;
                });
            },
            okButtonProps: {
                type: 'danger',
            },
            okText: t('OK'),
            cancelText: t('Cancel'),
        });
    };

    private handleCancelTraining = (e: React.FormEvent): void => {
        e.preventDefault();
        const {
            onTerminateTraining,
            checkJobsStatus,
        } = this.props;
        this.job_status = ModelStatus.STOPPING;
        this.isNotCancelable = true;
        this.forceUpdate();
        onTerminateTraining();
        checkJobsStatus(2000);
        setTimeout(this.updateButtonsStatus, 3000);
    };

    private onChangeBaseModel = (id: string): void => {
        const { originModelName: prevOriginModelName } = this.state;
        const { models } = this.props;
        const originModelName = models.find((model) => model.id.toString() === id)?.originModelName;
        if (originModelName && originModelName !== prevOriginModelName) {
            this.setState({
                originModelName,
                ...defaultConfig[originModelName],
            }, () => this.forceUpdate());
        }
    };

    private async checkCondition(): Promise<void> {
        const { taskId } = this.props;
        const conditions = await core.server.request(
            `${baseURL}/tensorflow/train/ready-for-train/${taskId}`, {
                method: 'GET',
            },
        );
        this.conditions = conditions;
        if (runningStatuses.includes(this.job_status) ||
            (
                this.conditions.data.count >= this.conditions.data.limit &&
                this.conditions.annot.count >= this.conditions.annot.limit &&
                !this.conditions.model.limited
            )
        ) {
            this.hasInsufficientData = false;
        } else {
            this.hasInsufficientData = true;
        }
        this.conditionInitialzed = true;
        this.forceUpdate();
    }

    private updateButtonsStatus(): void {
        this.isNotStartable = runningStatuses.includes(this.job_status);
        this.isNotCancelable = !cancelableStatuses.includes(this.job_status);
    }

    private renderTrainingSteps(): JSX.Element {
        const { form, plan, t } = this.props;
        const { steps } = this.state;

        let maxSteps = 1000;
        if (plan === 'Pro') {
            maxSteps = 10000;
        } else if (plan === 'Business') {
            maxSteps = 50000;
        }

        return (
            <Form.Item>
                <Text className='cvat-text-color'>
                    {t('Training steps')}
                </Text>
                {form.getFieldDecorator('steps', {
                    initialValue: steps,
                    rules: [{
                        required: true,
                        message: 'Please set maximum steps',
                    }, {
                        validator: validateSteps.bind(null, 10, maxSteps),
                    }],
                })(
                    <InputNumber min={10} max={maxSteps} disabled={this.isNotStartable} />,
                )}
            </Form.Item>
        );
    }

    private renderLearningRate(): JSX.Element {
        const { form } = this.props;
        const { t } = this.props;
        const { lr } = this.state;
        return (
            <Form.Item>
                <Text className='cvat-text-color'>
                    {t('Learning Rate')}
                </Text>
                {form.getFieldDecorator('lr', {
                    initialValue: lr,
                    rules: [{
                        required: true,
                        message: 'Please set initial learning rate',
                    }],
                })(
                    <InputNumber step={0.00001} disabled={this.isNotStartable} />,
                )}
            </Form.Item>
        );
    }

    private renderUseEarlyStopping(): JSX.Element {
        const { form } = this.props;
        const { t } = this.props;
        const { early_stopping } = this.state;
        return (
            <Form.Item>
                {form.getFieldDecorator('early_stopping', {
                    initialValue: early_stopping,
                    valuePropName: 'checked',
                })(
                    <Checkbox disabled={this.isNotStartable}>
                        {t('Use Early Stopping')}
                    </Checkbox>,
                )}
            </Form.Item>
        );
    }

    private renderPatience(): JSX.Element {
        const { form } = this.props;
        const { early_stopping } = this.state;
        const { t } = this.props;
        return (
            <Form.Item>
                <Text className='cvat-text-color'>
                    {t('Patience')}
                </Text>
                {form.getFieldDecorator('patience', {
                    initialValue: 100,
                    rules: [{
                        required: true,
                        message: 'Patience threshold on the early stopping',
                    }],
                })(
                    <InputNumber disabled={this.isNotStartable || !early_stopping} />,
                )}
            </Form.Item>
        );
    }

    private renderStatus(): JSX.Element {
        const running = runningStatuses.includes(this.job_status);
        const { t } = this.props;

        if (!this.conditionInitialzed) {
            return (<Spin style={{ margin: '0 50%' }} />);
        }
        if (running) {
            return (<Alert type='warning' message={`${t('Running job status')}: ${t(this.job_status)}`} />);
        }
        if (!!this.conditions.data && this.conditions.data.count < this.conditions.data.limit) {
            const { limit } = this.conditions.data;
            return (
                <Alert
                    type='error'
                    message={t('This task cannot be started to train')}
                    description={t('At least {{limit}} images or video frames are required.', { limit })}
                />
            );
        }
        if (!!this.conditions.model && this.conditions.model.limited) {
            return (
                <Alert
                    type='warning'
                    message={t('Number of models have reached to your limit')}
                    description={t('You can increase the limit by upgrading to a higher plan.')}
                />
            );
        }
        if (!!this.conditions.annot && this.conditions.annot.count < this.conditions.annot.limit) {
            const { limit } = this.conditions.annot;
            return (
                <Alert
                    type='warning'
                    message={t('Not ready for training')}
                    description={t('At least {{limit}} annotations are required.', { limit })}
                />
            );
        }
        return (<Alert type='info' message={t('Ready for training')} />);
    }

    private renderStartButton(): JSX.Element {
        const { t } = this.props;
        return (
            <Form.Item>
                <Button
                    type='primary'
                    htmlType='submit'
                    disabled={this.isNotStartable || this.hasInsufficientData}
                    block
                >
                    {t('Start')}
                </Button>
            </Form.Item>
        );
    }

    private renderCancelButton(): JSX.Element {
        const { t } = this.props;
        return (
            <Form.Item>
                <Button
                    type='primary'
                    htmlType='button'
                    onClick={this.handleCancelTraining}
                    disabled={this.isNotCancelable}
                    block
                >
                    {t('Cancel')}
                </Button>
            </Form.Item>
        );
    }

    private renderFirstStageFeaturesStride(): JSX.Element {
        const { form } = this.props;
        const { advanced_config } = this.state;
        if (!('first_stage_features_stride' in advanced_config)) return <></>;
        const { first_stage_features_stride } = advanced_config;
        return (
            <Form.Item label={<span>first_stage_features_stride</span>}>
                {form.getFieldDecorator('advanced_config.first_stage_features_stride', {
                    initialValue: first_stage_features_stride,
                    rules: [{
                        required: true,
                        message: 'The field is required.',
                    }, {
                        validator: isPositiveInteger,
                    }],
                })(
                    <Input size='large' type='number' disabled={this.isNotStartable} />,
                )}
            </Form.Item>
        );
    }

    private renderMomentumOptimizerValue(): JSX.Element {
        const { form } = this.props;
        const { advanced_config } = this.state;
        if (!('momentum_optimizer_value' in advanced_config)) return <></>;
        const { momentum_optimizer_value } = advanced_config;
        return (
            <Form.Item label={<span>momentum_optimizer_value</span>}>
                {form.getFieldDecorator('advanced_config.momentum_optimizer_value', {
                    initialValue: momentum_optimizer_value,
                    rules: [{
                        required: true,
                        message: 'The field is required.',
                    }, {
                        validator: isFloatRange.bind(null, 0.0, 1.0),
                    }],
                })(
                    <Input size='large' type='number' disabled={this.isNotStartable} />,
                )}
            </Form.Item>
        );
    }

    private renderScheduleStep(index: number): JSX.Element {
        const { form } = this.props;
        const { advanced_config } = this.state;
        if (!('schedules' in advanced_config)) return <></>;
        const { schedules } = advanced_config;
        const key = `advanced_config.schedules[${index}].step`;
        const { step } = schedules[index];
        return (
            <Form.Item label={<span>step</span>}>
                {form.getFieldDecorator(key, {
                    initialValue: step,
                    rules: [{
                        required: true,
                        message: 'The field is required.',
                    }, {
                        validator: isPositiveInteger,
                    }],
                })(
                    <Input size='large' type='number' disabled={this.isNotStartable} />,
                )}
            </Form.Item>
        );
    }

    private renderScheduleLearningRate(index: number): JSX.Element {
        const { form } = this.props;
        const { advanced_config } = this.state;
        if (!('schedules' in advanced_config)) return <></>;
        const { schedules } = advanced_config;
        const key = `advanced_config.schedules[${index}].learning_rate`;
        const { learning_rate } = schedules[index];
        return (
            <Form.Item label={<span>learning_rate</span>}>
                {form.getFieldDecorator(key, {
                    initialValue: learning_rate,
                    rules: [{
                        required: true,
                        message: 'The field is required.',
                    }, {
                        validator: isPositiveFloat,
                    }],
                })(
                    <Input size='large' type='number' disabled={this.isNotStartable} />,
                )}
            </Form.Item>
        );
    }

    private renderExponentialDecayStep(): JSX.Element {
        const { form } = this.props;
        const { advanced_config } = this.state;
        if (!('decay_steps' in advanced_config)) return <></>;
        const { decay_steps } = advanced_config;
        return (
            <Form.Item label={<span>decay_steps</span>}>
                {form.getFieldDecorator('advanced_config.decay_steps', {
                    initialValue: decay_steps,
                    rules: [{
                        required: true,
                        message: 'The field is required.',
                    }, {
                        validator: isPositiveInteger,
                    }],
                })(
                    <Input size='large' type='number' disabled={this.isNotStartable} />,
                )}
            </Form.Item>
        );
    }

    private renderExponentialDecayFactor(): JSX.Element {
        const { form } = this.props;
        const { advanced_config } = this.state;
        if (!('decay_factor' in advanced_config)) return <></>;
        const { decay_factor } = advanced_config;
        return (
            <Form.Item label={<span>decay_factor</span>}>
                {form.getFieldDecorator('advanced_config.decay_factor', {
                    initialValue: decay_factor,
                    rules: [{
                        required: true,
                        message: 'The field is required.',
                    }, {
                        validator: isPositiveFloat,
                    }],
                })(
                    <Input size='large' type='number' disabled={this.isNotStartable} />,
                )}
            </Form.Item>
        );
    }

    private renderDecay(): JSX.Element {
        const { form } = this.props;
        const { advanced_config } = this.state;
        if (!('decay' in advanced_config)) return <></>;
        const { decay } = advanced_config;
        return (
            <Form.Item label={<span>decay</span>}>
                {form.getFieldDecorator('advanced_config.decay', {
                    initialValue: decay,
                    rules: [{
                        required: true,
                        message: 'The field is required.',
                    }, {
                        validator: isPositiveFloat,
                    }],
                })(
                    <Input size='large' type='number' disabled={this.isNotStartable} />,
                )}
            </Form.Item>
        );
    }

    private renderEpsilon(): JSX.Element {
        const { form } = this.props;
        const { advanced_config } = this.state;
        if (!('epsilon' in advanced_config)) return <></>;
        const { epsilon } = advanced_config;
        return (
            <Form.Item label={<span>epsilon</span>}>
                {form.getFieldDecorator('advanced_config.epsilon', {
                    initialValue: epsilon,
                    rules: [{
                        required: true,
                        message: 'The field is required.',
                    }, {
                        validator: isPositiveFloat,
                    }],
                })(
                    <Input size='large' type='number' disabled={this.isNotStartable} />,
                )}
            </Form.Item>
        );
    }

    private renderRandomHorizontalFlip(): JSX.Element {
        const { form, t } = this.props;
        const { advanced_config: { random_horizontal_flip } } = this.state;
        return (
            <Form.Item>
                {form.getFieldDecorator('advanced_config.random_horizontal_flip', {
                    initialValue: random_horizontal_flip,
                    valuePropName: 'checked',
                })(
                    <Checkbox disabled={this.isNotStartable}>
                        <Text className='cvat-text-color'>
                            {t('random_horizontal_flip')}
                        </Text>
                    </Checkbox>,
                )}
            </Form.Item>
        );
    }

    private renderRandomVerticalFlip(): JSX.Element {
        const { form, t } = this.props;
        const { advanced_config: { random_vertical_flip } } = this.state;
        return (
            <Form.Item>
                {form.getFieldDecorator('advanced_config.random_vertical_flip', {
                    initialValue: random_vertical_flip,
                    valuePropName: 'checked',
                })(
                    <Checkbox disabled={this.isNotStartable}>
                        <Text className='cvat-text-color'>
                            {t('random_vertical_flip')}
                        </Text>
                    </Checkbox>,
                )}
            </Form.Item>
        );
    }

    private renderBaseModelSelector(): JSX.Element {
        const { form, models, t } = this.props;

        // Divide models by category from components/models-page/models-page.tsx
        const trainedModels = models.filter((model): boolean => model.type === 'trained' && model.status === ModelStatus.FINISHED);
        const uploadedModels = models.filter((model): boolean => model.type === 'openvino');
        // const integratedModels = models.filter((model): boolean => model.id === null);
        const integratedModels = models.filter((model): boolean => model.type === 'preinstalled');

        const makeOptions = (arr: Model[], translate: boolean): JSX.Element[] => arr.map((model: Model) => {
            const value = (model.id !== null) ? model.id.toString() : model.name;
            const name = translate ? t(model.name) : model.name;
            return (
                // translation model.name from actions/models-actions.ts
                <Select.Option key={value} value={value}>
                    {name}
                </Select.Option>
            );
        });

        let defaultModel = null;
        if (integratedModels.length > 0) {
            [defaultModel] = integratedModels;
        } else if (uploadedModels.length > 0) {
            [defaultModel] = uploadedModels;
        } else if (trainedModels.length > 0) {
            [defaultModel] = trainedModels;
        }
        let defaultValue = '';
        if (defaultModel) {
            if (defaultModel.id !== null) {
                defaultValue = defaultModel.id.toString();
            } else {
                defaultValue = defaultModel.name;
            }
        }

        return (
            <Form.Item>
                {form.getFieldDecorator('advanced_config.base_model_id', {
                    initialValue: defaultValue,
                })(
                    <Select onChange={(value: string) => this.onChangeBaseModel(value)} disabled={this.isNotStartable}>
                        {integratedModels &&
                        (
                            <Select.OptGroup label={t('Pre-Trained models')}>
                                {makeOptions(integratedModels, true)}
                            </Select.OptGroup>
                        )}
                        {uploadedModels &&
                            (
                                <Select.OptGroup label='Uploaded models'>
                                    {makeOptions(uploadedModels, false)}
                                </Select.OptGroup>
                            )}
                        {trainedModels &&
                            (
                                <Select.OptGroup label={t('User\'s models')}>
                                    {makeOptions(trainedModels, false)}
                                </Select.OptGroup>
                            )}
                    </Select>,
                )}
            </Form.Item>
        );
    }

    private renderAdvancedBlockRCNN(): JSX.Element {
        return (
            <>
                <Row type='flex' justify='start'>
                    <Col span={24}>
                        <Text className='cvat-text-color'>Scheduled degradation of learning rate</Text>
                    </Col>
                </Row>
                <Row type='flex' justify='start'>
                    <Col span={5}>
                        <Text className='cvat-text-color'>1st degradation: </Text>
                    </Col>
                    <Col span={5}>{this.renderScheduleStep(0)}</Col>
                    <Col span={5} offset={1}>{this.renderScheduleLearningRate(0)}</Col>
                </Row>
                <Row>
                    <Col span={5}>
                        <Text className='cvat-text-color'>2nd degradation: </Text>
                    </Col>
                    <Col span={5}>{this.renderScheduleStep(1)}</Col>
                    <Col span={5} offset={1}>{this.renderScheduleLearningRate(1)}</Col>
                </Row>
            </>
        );
    }

    private renderAdvancedBlockSSD(): JSX.Element {
        return (
            <>
                <Row type='flex' justify='start'>
                    <Col span={24}>
                        <Text className='cvat-text-color'>RMSProp optimizer</Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={5}>
                        <Text className='cvat-text-color'>General: </Text>
                    </Col>
                    <Col span={5}>{this.renderDecay()}</Col>
                    <Col span={5} offset={1}>{this.renderEpsilon()}</Col>
                </Row>
                <Row type='flex' justify='start'>
                    <Col span={5}>
                        <Text className='cvat-text-color'>Exponential decay: </Text>
                    </Col>
                    <Col span={5}>{this.renderExponentialDecayStep()}</Col>
                    <Col span={5} offset={1}>{this.renderExponentialDecayFactor()}</Col>
                </Row>
            </>
        );
    }

    private renderAdvancedBlock(): JSX.Element {
        const { t } = this.props;
        const { originModelName } = this.state;
        return (
            <Collapse>
                <Collapse.Panel
                    key='1'
                    header={
                        <Text className='cvat-collapse-panel-title'>{t('Advanced configuration')}</Text>
                    }
                >
                    <Row gutter={[0, 12]}>
                        <Col>
                            <Text>{t('Base Model')}</Text>
                            {this.renderBaseModelSelector()}
                        </Col>
                    </Row>
                    {originModelName === 'faster_rcnn_resnet101_coco' && this.renderAdvancedBlockRCNN()}
                    {originModelName === 'ssd_mobilenet_v2_coco' && this.renderAdvancedBlockSSD()}
                    <Row type='flex' justify='start'>
                        <Col span={5}>{this.renderMomentumOptimizerValue()}</Col>
                    </Row>
                    {/* <Row>
                        <Col span={12}>
                            {this.renderUseEarlyStopping()}
                        </Col>
                        <Col span={12}>
                            {this.renderPatience()}
                        </Col>
                    </Row> */}
                    <Row type='flex' justify='start'>
                        <Col span={5}>{this.renderFirstStageFeaturesStride()}</Col>
                    </Row>
                </Collapse.Panel>
            </Collapse>
        );
    }

    public render(): JSX.Element {
        this.updateButtonsStatus();

        if (this.hasInsufficientData) {
            return (
                <Row>
                    <Col className='eyes-train-status'>
                        {this.renderStatus()}
                    </Col>
                </Row>
            );
        }
        return (
            <Form onSubmit={this.handleSubmitTraining} className='cvat-training-form'>
                <Row>
                    <Col span={14}>
                        <Row>
                            <Col span={12}>
                                {this.renderTrainingSteps()}
                            </Col>
                            <Col span={12}>
                                {this.renderLearningRate()}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>{this.renderRandomHorizontalFlip()}</Col>
                            <Col span={12}>{this.renderRandomVerticalFlip()}</Col>
                        </Row>
                    </Col>
                    <Col span={9} offset={1}>
                        <Row gutter={[8, 24]}>
                            <Col className='eyes-train-status' span={24}>
                                {this.renderStatus()}
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={12}>
                                {this.renderStartButton()}
                            </Col>
                            <Col span={12}>
                                {this.renderCancelButton()}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {this.renderAdvancedBlock()}
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default withTranslation()(Form.create<Props>()(TrainingFormComponent));
