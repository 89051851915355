// Copyright (C) 2020 Humanome Lab., Inc.
//

import React from 'react';
import { connect } from 'react-redux';

import { CombinedState } from 'reducers/interfaces';
import AdminPageComponent from 'components/admin-page/admin-page';
import {
    getUsersAsync,
    activateUserAsync,
    deactivateUserAsync,
    updateUserAsync,
} from 'actions/admin-actions';

interface StateToProps {
    fetching: boolean;
    users: any[];
}

interface DispatchToProps {
    getUsers: () => void;
    activateUser: (cognitoId: string) => void;
    deactivateUser: (cognitoId: string) => void;
    updateUser: (username: string, attributes: any[]) => void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    return {
        fetching: state.admin.fetching,
        users: state.admin.users,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        getUsers: () => dispatch(getUsersAsync()),
        activateUser: (cognitoId: string) => {
            dispatch(activateUserAsync(cognitoId));
        },
        deactivateUser: (cognitoId: string) => {
            dispatch(deactivateUserAsync(cognitoId));
        },
        updateUser: (username: string, attributes: any[]) => {
            dispatch(updateUserAsync(username, attributes));
        },
    };
}

function AdminPageContainer(props: DispatchToProps & StateToProps): JSX.Element {
    return (
        <AdminPageComponent {...props} />
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPageContainer);
