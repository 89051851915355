import React from 'react';

import { RouteComponentProps } from 'react-router';
import { Link, withRouter } from 'react-router-dom';

import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import store from 'store';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import { Row, Col } from 'antd/lib/grid';
import { passwordValidator } from 'utils/validation';

interface NewPasswordComponentProps {
    fetching: boolean;
    onSubmit: (code: string, password:string) => void;
}

interface NewPasswordComponentState {
    code: string,
    password: string,
    validPassword1: boolean,
    validPassword2: boolean,
}

type NewPasswordFormProps = NewPasswordComponentProps & RouteComponentProps & FormComponentProps;

class NewPasswordFormComponent extends React.Component<NewPasswordFormProps, NewPasswordComponentState> {
    private passwordInput = React.createRef<Input>();

    constructor(props: any) {
        super(props);
        this.state = {
            code: '',
            password: '',
            validPassword1: false,
            validPassword2: false,
        };
    }

    public componentDidMount(): void {
        this.passwordInput.current?.focus();
    }

    private validateConfirmation = (rule: any, value: any, callback: any): void => {
        const { form } = this.props;
        let valid = false;
        if (value) {
            if (value !== form.getFieldValue('password1')) {
                callback('Two passwords that you enter is inconsistent!');
            } else {
                callback();
                valid = true;
            }
        } else {
            callback();
        }
        this.setState({ validPassword2: valid });
    };

    private validatePassword = (_: any, value: any, callback: any): void => {
        const { form } = this.props;
        let valid = true;
        const msg = passwordValidator(value);
        if (msg) {
            callback(msg);
            valid = false;
        }

        if (value) {
            form.validateFields(['password2'], { force: true });
        } else {
            valid = false;
        }
        this.setState({ validPassword1: valid });
        callback();
    };

    render(): JSX.Element {
        const { fetching, onSubmit, form } = this.props;
        const {
            code,
            password,
            validPassword1,
            validPassword2,
        } = this.state;

        const email = store.get('email');

        return (
            <Form onSubmit={(e) => {
                e.preventDefault();
                onSubmit(code, password);
            }}
            >
                <Form.Item hasFeedback>
                    {form.getFieldDecorator('password1', {
                        rules: [{
                            required: true,
                            message: 'Please input your password!',
                        }, {
                            validator: this.validatePassword,
                        }],
                    })(<Input.Password
                        onChange={(e) => this.setState({ password: e.target.value })}
                        prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        autoComplete='new-password'
                        placeholder='Password'
                        type='password'
                        ref={this.passwordInput}
                    />)}
                </Form.Item>
                <Form.Item hasFeedback>
                    {form.getFieldDecorator('password2', {
                        rules: [{
                            required: true,
                            message: 'Please confirm your password!',
                        }, {
                            validator: this.validateConfirmation,
                        }],
                    })(<Input.Password
                        autoComplete='new-password'
                        prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder='Confirm password'
                    />)}
                </Form.Item>
                <Row style={{ marginTop: 10 }} type='flex' justify='start' align='top'>
                    <Col>
                        <Text strong>
                            {`Verification code has been sent to ${email}`}
                        </Text>
                    </Col>
                </Row>

                <Form.Item>
                    <Input
                        name='code'
                        onChange={(e) => this.setState({ code: e.target.value })}
                        placeholder='Verification code'
                        required
                    />
                </Form.Item>
                <Button
                    type='primary'
                    htmlType='submit'
                    loading={fetching}
                    disabled={fetching || !validPassword1 || !validPassword2}
                >
                    Change password
                </Button>
            </Form>
        );
    }
}

const NewPasswordForm = Form.create<NewPasswordFormProps>()(withRouter(NewPasswordFormComponent));

function NewPasswordComponent(
    props: NewPasswordComponentProps & RouteComponentProps,
): JSX.Element {
    const sizes = {
        xs: { span: 14 },
        sm: { span: 14 },
        md: { span: 10 },
        lg: { span: 4 },
        xl: { span: 4 },
    };

    return (
        <Row type='flex' justify='center' align='middle'>
            <Col {...sizes}>
                <Title level={2}>Change your password</Title>
                <NewPasswordForm {...props} />
                <Row style={{ marginTop: 10 }} type='flex' justify='start' align='top'>
                    <Col>
                        <Text strong>
                            Switch to another account?
                            <Link to='/auth/login'> Login </Link>
                        </Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default withRouter(NewPasswordComponent);
