// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Menu from 'antd/lib/menu';
import Icon from 'antd/lib/icon';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

interface Props {
    menuKey: string;
    exporters: string[];
    exportActivities: string[] | null;
    jobsLength: number;
}

export default function ExportSubmenu(props: Props): JSX.Element {
    const {
        menuKey,
        exporters,
        exportActivities,
        jobsLength,
    } = props;

    const { t } = useTranslation();

    return (
        <Menu.SubMenu key={menuKey} title={t('Export as a dataset')} disabled={jobsLength === 0}>
            {
                exporters.map((exporter: string): JSX.Element => {
                    const pending = (exportActivities || []).includes(exporter);
                    return (
                        <Menu.Item
                            key={exporter}
                            disabled={pending}
                            className='cvat-menu-export-submenu-item'
                        >
                            <Icon type='export' />
                            <Text>{exporter}</Text>
                            {pending && <Icon style={{ marginLeft: 10 }} type='loading' />}
                        </Menu.Item>
                    );
                })
            }
        </Menu.SubMenu>
    );
}
