import React from 'react';
import { connect } from 'react-redux';

import { CombinedState, Model } from 'reducers/interfaces';

import { updateModelAsync } from 'actions/models-actions';
import DetailsComponent from 'components/trained-model-page/details';


interface StateToProps {
}

interface DispatchToProps {
    onModelUpdate: (model: Model) => void;
}

interface OwnProps {
    model: Model;
}

function mapStateToProps(state: CombinedState): StateToProps {
    return {};
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onModelUpdate: (model: Model): void => dispatch(updateModelAsync(model)),
    };
}

function DetailsContainer(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    return (
        <DetailsComponent {...props} />
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DetailsContainer);
