// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Row, Col } from 'antd/lib/grid';
import Alert from 'antd/lib/alert';
import Button from 'antd/lib/button';
import Collapse from 'antd/lib/collapse';
import notification from 'antd/lib/notification';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';
import { withTranslation, WithTranslation } from 'react-i18next';

import FileManager from 'containers/file-manager/file-manager';
import BasicConfigurationForm, { BaseConfiguration } from './basic-configuration-form';
import AdvancedConfigurationForm, { AdvancedConfiguration } from './advanced-configuration-form';
import LabelsEditor from '../labels-editor/labels-editor';
import { Files } from '../file-manager/file-manager';

export interface CreateTaskData {
    basic: BaseConfiguration;
    advanced: AdvancedConfiguration;
    labels: any[];
    files: Files;
}

interface Props extends WithTranslation, RouteComponentProps {
    onCreate: (data: CreateTaskData) => void;
    id: number | null;
    status: string;
    installedGit: boolean;
    isReachedLimit: boolean;
    initialized: boolean;
}

type State = CreateTaskData;

const defaultState = {
    basic: {
        name: '',
    },
    advanced: {
        zOrder: false,
        lfs: false,
        useZipChunks: true,
        applyOrientation: false,
    },
    labels: [],
    files: {
        local: [],
        share: [],
        remote: [],
    },
};

class CreateTaskContent extends React.PureComponent<Props, State> {
    private basicConfigurationComponent: any;
    private advancedConfigurationComponent: any;
    private fileManagerContainer: any;

    public constructor(props: Props) {
        super(props);
        this.state = { ...defaultState };
    }

    public componentDidUpdate(prevProps: Props): void {
        const { status, id, history } = this.props;
        const { t } = this.props;

        if (status === 'CREATED' && prevProps.status !== 'CREATED') {
            notification.info({
                // Humanome Eyes
                // message: 'The task has been created',
                message: (
                    <>
                        <Paragraph>
                            {t('The task has been created')}
                        </Paragraph>
                        <Paragraph>
                            {t('Go to ')}
                            <a
                                href={`/tasks/${id}`}
                                onClick={(e: React.MouseEvent): void => {
                                    e.preventDefault();
                                    notification.destroy();
                                    history.push(`/tasks/${id}`);
                                }}
                            >
                                {t('task page')}
                            </a>
                        </Paragraph>
                    </>),
                duration: 10,
                className: 'cvat-notification-create-task-success',
            });

            this.basicConfigurationComponent.resetFields();
            if (this.advancedConfigurationComponent) {
                this.advancedConfigurationComponent.resetFields();
            }

            this.fileManagerContainer.reset();

            this.setState({
                ...defaultState,
            });
        } else if (status === 'CANCELED' && prevProps.status !== 'CANCELED') {
            notification.info({
                message: (
                    <>
                        <Paragraph>
                            {t('The task has been canceled')}
                        </Paragraph>
                    </>),
                duration: 10,
                className: 'cvat-notification-create-task-canceled',
            });

            this.basicConfigurationComponent.resetFields();
            if (this.advancedConfigurationComponent) {
                this.advancedConfigurationComponent.resetFields();
            }

            this.fileManagerContainer.reset();

            this.setState({
                ...defaultState,
            });
        }
    }

    private validateLabels = (): boolean => {
        const { labels } = this.state;
        return !!labels.length;
    };

    private validateFiles = (): boolean => {
        const files = this.fileManagerContainer.getFiles();
        this.setState({
            files,
        });
        const totalLen = Object.keys(files).reduce(
            (acc, key) => acc + files[key].length, 0,
        );

        return !!totalLen;
    };

    private validateFileSize = (): boolean => {
        return this.fileManagerContainer.validateFileSize();
    };

    private validateDuplicates = (): boolean => {
        return !this.fileManagerContainer.checkDuplicates();
    };

    private handleSubmitBasicConfiguration = (values: BaseConfiguration): void => {
        this.setState({
            basic: { ...values },
        });
    };

    private handleSubmitAdvancedConfiguration = (values: AdvancedConfiguration): void => {
        this.setState({
            advanced: { ...values },
        });
    };

    private handleSubmitClick = (): void => {
        const { t } = this.props;

        if (!this.validateLabels()) {
            notification.error({
                message: t('Could not create a task'),
                description: t('A task must contain at least one label'),
                className: 'cvat-notification-create-task-fail',
            });
            return;
        }

        if (!this.validateFiles()) {
            notification.error({
                message: t('Could not create a task'),
                description: t('A task must contain at least one file'),
                className: 'cvat-notification-create-task-fail',
            });
            return;
        }

        if (!this.validateFileSize()) {
            notification.error({
                message: t('Could not create a task'),
                description: t('Total file size exceeds limit'),
                className: 'cvat-notification-create-task-fail',
            });
            return;
        }

        if (!this.validateDuplicates()) {
            notification.error({
                message: t('Could not create a task'),
                description: t('Some files have the same names'),
                className: 'cvat-notification-create-task-fail',
            });
            return;
        }

        this.basicConfigurationComponent.submit()
            .then(() => {
                if (this.advancedConfigurationComponent) {
                    return this.advancedConfigurationComponent.submit();
                }

                return new Promise<void>((resolve): void => {
                    resolve();
                });
            }).then((): void => {
                const { onCreate } = this.props;
                onCreate(this.state);
            }).catch((error: Error): void => {
                notification.error({
                    message: 'Could not create a task',
                    description: error.toString(),
                    className: 'cvat-notification-create-task-fail',
                });
            });
    };

    private renderBasicBlock(): JSX.Element {
        return (
            <Col span={24}>
                <BasicConfigurationForm
                    wrappedComponentRef={
                        (component: any): void => { this.basicConfigurationComponent = component; }
                    }
                    onSubmit={this.handleSubmitBasicConfiguration}
                />
            </Col>
        );
    }

    private renderLabelsBlock(): JSX.Element {
        const { t } = this.props;
        const { labels } = this.state;

        return (
            <Col span={24}>
                <Text type='danger'>* </Text>
                <Text className='cvat-text-color'>
                    {t('Labels_c')}
                    :
                </Text>
                <LabelsEditor
                    labels={labels}
                    onSubmit={
                        (newLabels): void => {
                            this.setState({
                                labels: newLabels,
                            });
                        }
                    }
                />
            </Col>
        );
    }

    private renderFilesBlock(): JSX.Element {
        const { t } = this.props;
        return (
            <Col span={24}>
                <Text type='danger'>* </Text>
                <Text className='cvat-text-color'>
                    {t('Select files')}
                    :
                </Text>
                <FileManager
                    ref={
                        (container: any): void => { this.fileManagerContainer = container; }
                    }
                    withRemote
                    directoryUploadable
                    hintText={t('Support for a bulk images or a single video')}
                />
            </Col>
        );
    }

    private renderAdvancedBlock(): JSX.Element {
        const { installedGit, t } = this.props;
        return (
            <Col span={24}>
                <Collapse>
                    <Collapse.Panel
                        key='1'
                        header={
                            <Text className='cvat-title'>{t('Advanced configuration')}</Text>
                        }
                    >
                        <AdvancedConfigurationForm
                            installedGit={installedGit}
                            wrappedComponentRef={
                                (component: any): void => {
                                    this.advancedConfigurationComponent = component;
                                }
                            }
                            onSubmit={this.handleSubmitAdvancedConfiguration}
                        />
                    </Collapse.Panel>
                </Collapse>
            </Col>
        );
    }

    public render(): JSX.Element {
        const {
            status, isReachedLimit, initialized, history, t,
        } = this.props;
        const loading = !!status && status !== 'CREATED' && status !== 'FAILED' && status !== 'CANCELED';
        if (!initialized) {
            return <></>;
        }
        if (isReachedLimit) {
            return (
                <Row type='flex' justify='end' className='cvat-create-task-content'>
                    <Col span={24}>
                        <Alert
                            message={t('Cannot create a task')}
                            description={t('TASK_LIMIT_MESSAGE')}
                            type='warning'
                            showIcon
                        />
                    </Col>
                    <Col>
                        <Button
                            loading={loading}
                            disabled={loading}
                            type='primary'
                            onClick={() => history.goBack()}
                        >
                            {t('Back')}
                        </Button>
                    </Col>
                </Row>
            );
        }
        return (
            <Row type='flex' justify='space-between' align='middle' className='cvat-create-task-content'>
                <Col span={24}>
                    <Text className='cvat-title'>{t('Basic configuration')}</Text>
                </Col>

                { this.renderBasicBlock() }
                { this.renderLabelsBlock() }
                { this.renderFilesBlock() }
                { this.renderAdvancedBlock() }

                <Col span={18}>
                    {/* {loading ? <Alert message={status} /> : null} */}
                </Col>
                <Col className='cvat-create-task-submit-section'>
                    <Button
                        loading={loading}
                        disabled={loading}
                        type='primary'
                        onClick={this.handleSubmitClick}
                    >
                        {t('SubmitUpload')}
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default withTranslation()(withRouter(CreateTaskContent));
