// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Menu from 'antd/lib/menu';
import Icon from 'antd/lib/icon';
import Upload from 'antd/lib/upload';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

interface Props {
    menuKey: string;
    loaders: string[];
    loadActivity: string | null;
    inferenceIsActive: boolean;
    isTraining: boolean;
    jobsLength: number;
    onFileUpload(file: File): void;
}

export default function LoadSubmenu(props: Props): JSX.Element {
    const {
        menuKey,
        loaders,
        loadActivity,
        inferenceIsActive,
        isTraining,
        jobsLength,
        onFileUpload,
    } = props;

    const { t } = useTranslation();

    return (
        <Menu.SubMenu key={menuKey} title={t('Upload annotations')} disabled={jobsLength === 0}>
            {
                loaders.map((_loader: string): JSX.Element => {
                    const [loader, accept] = _loader.split('::');
                    const pending = loadActivity === loader;
                    return (
                        <Menu.Item
                            key={loader}
                            disabled={isTraining || !!loadActivity || inferenceIsActive}
                            className='cvat-menu-load-submenu-item'
                        >
                            <Upload
                                accept={accept}
                                multiple={false}
                                showUploadList={false}
                                beforeUpload={(file: File): boolean => {
                                    onFileUpload(file);
                                    return false;
                                }}
                            >
                                <Button
                                    block
                                    type='link'
                                    disabled={isTraining || !!loadActivity || inferenceIsActive}
                                    className='cvat-menu-load-submenu-item-button'
                                >
                                    <Icon type='upload' />
                                    <Text>{loader}</Text>
                                    {pending && <Icon style={{ marginLeft: 10 }} type='loading' />}
                                </Button>
                            </Upload>

                        </Menu.Item>
                    );
                })
            }
        </Menu.SubMenu>
    );
}
