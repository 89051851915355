// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Icon from 'antd/lib/icon';
import Select from 'antd/lib/select';
import Checkbox from 'antd/lib/checkbox';
import InputNumber from 'antd/lib/input-number';
import Tooltip from 'antd/lib/tooltip';
import Modal from 'antd/lib/modal';
import Tag from 'antd/lib/tag';
import Spin from 'antd/lib/spin';
import notification from 'antd/lib/notification';
import { withTranslation, WithTranslation } from 'react-i18next';
import { clamp } from 'utils/math';

import {
    Model,
    ModelStatus,
    StringObject,
} from 'reducers/interfaces';

interface Props extends WithTranslation {
    modelsFetching: boolean;
    modelsInitialized: boolean;
    models: Model[];
    activeProcesses: StringObject;
    visible: boolean;
    taskInstance: any;
    getModels(): void;
    closeDialog(): void;
    runInference(
        taskInstance: any,
        model: Model,
        mapping: StringObject,
        cleanOut: boolean,
        threshold: number,
    ): void;
}

interface State {
    selectedModel: string | null;
    cleanOut: boolean;
    mapping: StringObject;
    colors: StringObject;
    matching: {
        model: string;
        task: string;
    };
    threshold: number;
}

function colorGenerator(): () => string {
    const values = [
        'magenta', 'green', 'geekblue',
        'orange', 'red', 'cyan',
        'blue', 'volcano', 'purple',
    ];

    let index = 0;

    return (): string => {
        const color = values[index++];
        if (index >= values.length) {
            index = 0;
        }

        return color;
    };
}

const nextColor = colorGenerator();

const minThreshold = 0.01;
const maxThreshold = 1;
const initThreshold = 0.5;

class ModelRunnerModalComponent extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            selectedModel: null,
            mapping: {},
            colors: {},
            cleanOut: true,
            matching: {
                model: '',
                task: '',
            },
            threshold: initThreshold,
        };
    }

    public componentDidUpdate(prevProps: Props, prevState: State): void {
        const {
            taskInstance,
            modelsInitialized,
            modelsFetching,
            models,
            visible,
            getModels,
            t,
        } = this.props;

        const {
            selectedModel,
        } = this.state;

        if (!modelsInitialized && !modelsFetching) {
            getModels();
        }

        if (!prevProps.visible && visible) {
            this.setState({
                selectedModel: null,
                mapping: {},
                matching: {
                    model: '',
                    task: '',
                },
                cleanOut: true,
                threshold: initThreshold,
            });
        }

        if (selectedModel && prevState.selectedModel !== selectedModel) {
            const selectedModelInstance = models
                .filter((model) => model.name === selectedModel)[0];

            if (!selectedModelInstance.labels.length) {
                notification.warning({
                    message: t('The selected model does not include any lables'),
                });
            }

            let taskLabels: string[] = taskInstance.labels
                .map((label: any): string => label.name);
            const [defaultMapping, defaultColors]: StringObject[] = selectedModelInstance.labels
                .reduce((acc: StringObject[], label): StringObject[] => {
                    if (taskLabels.includes(label)) {
                        acc[0][label] = label;
                        acc[1][label] = nextColor();
                        taskLabels = taskLabels.filter((_label): boolean => _label !== label);
                    }

                    return acc;
                }, [{}, {}]);

            this.setState({
                mapping: defaultMapping,
                colors: defaultColors,
            });
        }
    }

    private renderModelSelector(): JSX.Element {
        const { models, t } = this.props;

        return (
            <Row type='flex' align='middle'>
                <Col span={4}>{t('Model:')}</Col>
                <Col span={19}>
                    <Select
                        placeholder={t('Select a model')}
                        style={{ width: '100%' }}
                        onChange={(value: string): void => this.setState({
                            selectedModel: value,
                            mapping: {},
                        })}
                    >
                        {models
                            .filter((model) => (model.type === 'preinstalled' ||
                                model.status === ModelStatus.FINISHED))
                            .map((model): JSX.Element => {
                                let printNameStr = model.name;
                                // if model is pretrained-model, translate model name
                                if (model.id === null || model.id > 2 ** 32) {
                                    printNameStr = t(printNameStr);
                                }

                                return (
                                    <Select.Option key={model.name}>
                                        {printNameStr}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Col>
            </Row>
        );
    }

    private renderMappingTag(modelLabel: string, taskLabel: string): JSX.Element {
        const { t } = this.props;
        const {
            colors,
            mapping,
        } = this.state;

        return (
            <Row key={`${modelLabel}-${taskLabel}`} type='flex' justify='start' align='middle'>
                <Col span={10}>
                    <Tag color={colors[modelLabel]}>{modelLabel}</Tag>
                </Col>
                <Col span={10} offset={1}>
                    <Tag color={colors[modelLabel]}>{taskLabel}</Tag>
                </Col>
                <Col span={1} offset={1}>
                    <Tooltip title={t('Remove the mapped values')}>
                        <Icon
                            className='cvat-run-model-dialog-remove-mapping-icon'
                            type='close-circle'
                            onClick={(): void => {
                                const newMapping = { ...mapping };
                                delete newMapping[modelLabel];
                                this.setState({
                                    mapping: newMapping,
                                });
                            }}
                        />
                    </Tooltip>
                </Col>
            </Row>
        );
    }

    private renderMappingInputSelector(
        value: string,
        current: string,
        options: string[],
    ): JSX.Element {
        const {
            matching,
            mapping,
            colors,
        } = this.state;

        return (
            <Select
                value={value}
                placeholder={`${current} labels`}
                style={{ width: '100%' }}
                onChange={(selectedValue: string): void => {
                    const anotherValue = current === 'Model' ?
                        matching.task : matching.model;

                    if (!anotherValue) {
                        const newMatching = { ...matching };
                        if (current === 'Model') {
                            newMatching.model = selectedValue;
                        } else {
                            newMatching.task = selectedValue;
                        }
                        this.setState({
                            matching: newMatching,
                        });
                    } else {
                        const newColors = { ...colors };
                        const newMapping = { ...mapping };

                        if (current === 'Model') {
                            newColors[selectedValue] = nextColor();
                            newMapping[selectedValue] = anotherValue;
                        } else {
                            newColors[anotherValue] = nextColor();
                            newMapping[anotherValue] = selectedValue;
                        }

                        this.setState({
                            colors: newColors,
                            mapping: newMapping,
                            matching: {
                                task: '',
                                model: '',
                            },
                        });
                    }
                }}
            >
                {options.map((label: string): JSX.Element => (
                    <Select.Option key={label}>
                        {label}
                    </Select.Option>
                ))}
            </Select>
        );
    }

    private renderMappingHeader(): JSX.Element {
        const { t } = this.props;
        return (
            <div>
                <Row type='flex' justify='start' align='middle'>
                    <Col>
                        {t('Label Mappping')}
                    </Col>
                </Row>

                <Row type='flex' justify='start' align='middle'>
                    <Col span={10}>
                        {t('Model Labels')}
                    </Col>
                    <Col span={10} offset={1}>
                        {t('Task Labels')}
                    </Col>
                </Row>
            </div>
        );
    }

    private renderMappingInput(
        availableModelLabels: string[],
        availableTaskLabels: string[],
    ): JSX.Element {
        const { t } = this.props;
        const { matching } = this.state;
        return (
            <Row type='flex' justify='start' align='middle'>
                <Col span={10}>
                    {this.renderMappingInputSelector(
                        matching.model,
                        'Model',
                        availableModelLabels,
                    )}
                </Col>
                <Col span={10} offset={1}>
                    {this.renderMappingInputSelector(
                        matching.task,
                        'Task',
                        availableTaskLabels,
                    )}
                </Col>
                <Col span={1} offset={1}>
                    <Tooltip title={t('Specify a label mapping between model labels and task labels')}>
                        <Icon className='cvat-info-circle-icon' type='question-circle' />
                    </Tooltip>
                </Col>
            </Row>
        );
    }

    private renderContent(): JSX.Element {
        const {
            selectedModel,
            cleanOut,
            mapping,
            threshold,
        } = this.state;
        const {
            models,
            taskInstance,
            t,
        } = this.props;

        const model = selectedModel && models
            .filter((_model): boolean => _model.name === selectedModel)[0];

        const excludedModelLabels: string[] = Object.keys(mapping);
        const withMapping = model;
        const tags = withMapping ? excludedModelLabels
            .map((modelLabel: string) => this.renderMappingTag(
                modelLabel,
                mapping[modelLabel],
            )) : [];

        const availableModelLabels = model ? model.labels
            .filter(
                (label: string) => !excludedModelLabels.includes(label),
            ) : [];
        const taskLabels = taskInstance.labels.map(
            (label: any) => label.name,
        );

        const mappingISAvailable = !!availableModelLabels.length &&
            !!taskLabels.length;

        return (
            <div className='cvat-run-model-dialog'>
                { this.renderModelSelector() }
                { withMapping && this.renderMappingHeader() }
                { withMapping && tags }
                { withMapping &&
                    mappingISAvailable &&
                    this.renderMappingInput(availableModelLabels, taskLabels)}
                { withMapping && (
                    <div>
                        <Checkbox
                            checked={cleanOut}
                            onChange={(e: any): void => this.setState({
                                cleanOut: e.target.checked,
                            })}
                        >
                            {t('Clean old annotations')}
                        </Checkbox>
                    </div>
                )}
                <Row type='flex'>
                    <Col span={4}>{t('threshold')}</Col>
                    <Col>
                        <InputNumber
                            min={minThreshold}
                            max={maxThreshold}
                            step={0.01}
                            value={threshold}
                            onChange={(value: number | undefined): void => {
                                let thresholdValue = initThreshold;
                                if (typeof (value) === 'number') {
                                    thresholdValue = clamp(
                                        value,
                                        minThreshold,
                                        maxThreshold,
                                    );
                                }
                                this.setState({
                                    threshold: thresholdValue,
                                });
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    public render(): JSX.Element | false {
        const {
            selectedModel,
            mapping,
            cleanOut,
            threshold,
        } = this.state;

        const {
            models,
            visible,
            taskInstance,
            modelsInitialized,
            runInference,
            closeDialog,
            t,
        } = this.props;

        const activeModel = models.filter(
            (model): boolean => model.name === selectedModel,
        )[0];

        const enabledSubmit = (!!activeModel &&
            activeModel.primary) || !!Object.keys(mapping).length;

        return (
            visible && (
                <Modal
                    closable={false}
                    okType='primary'
                    okText={t('Submit')}
                    cancelText={t('Cancel')}
                    onOk={(): void => {
                        runInference(
                            taskInstance,
                            models
                                .filter((model): boolean => model.name === selectedModel)[0],
                            mapping,
                            cleanOut,
                            threshold,
                        );
                        closeDialog();
                    }}
                    onCancel={(): void => closeDialog()}
                    okButtonProps={{ disabled: !enabledSubmit }}
                    title={t('AI annotation')}
                    visible
                >
                    {!modelsInitialized &&
                        <Spin size='large' className='cvat-spinner' />}
                    {modelsInitialized && this.renderContent()}
                </Modal>
            )
        );
    }
}

export default withTranslation()(ModelRunnerModalComponent);
