// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Form, { FormComponentProps } from 'antd/lib/form/Form';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import Checkbox from 'antd/lib/checkbox';
import Select from 'antd/lib/select';
import Text from 'antd/lib/typography/Text';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Row, Col } from 'antd/lib/grid';
import { Tooltip } from 'antd';
import store from 'store';
import { changeLanguage } from 'i18n';
import { passwordValidator, oldPasswordValidator, usernameValidator } from 'utils/validation';

// import passwordReset from 'components/password-reset';

import { UserAgreement, Language, ProfileData } from 'reducers/interfaces';

type UpdateProfileFormProps = {
    fetching: boolean;
    userAgreements: UserAgreement[];
    onSubmit(ProfileData: ProfileData): void;
    onEmailSubmit(oldEmail: string, newEmail: string): void;
    // onDeactivateAccount(): void;
    initialData: ProfileData;
    passwordRequired: boolean;
    t: TFunction;
} & FormComponentProps & WithTranslation;

const isEmpty = (value: string): boolean => (!value || value.length === 0);
const isNotEmpty = (value: string): boolean => (!!value && value.length > 0);

class UpdateProfileFormComponent
    extends React.PureComponent<UpdateProfileFormProps> {
    private getLanguage(): string {
        const { i18n } = this.props;
        return i18n.language ? i18n.language : Language.English;
    }

    private validateOldPassword = (_: any, value: any, callback: any): void => {
        const { form, passwordRequired } = this.props;
        const { t } = this.props;
        if (isEmpty(value) && isNotEmpty(form.getFieldValue('password1'))) {
            callback(t('Please input your old password!'));
        }

        if (passwordRequired || isNotEmpty(value)) {
            const msg = oldPasswordValidator(value);
            if (msg) {
                callback(t(msg));
            }
        }
        callback();
    };

    private validatePassword = (_: any, value: any, callback: any): void => {
        const { form, passwordRequired } = this.props;
        const { t } = this.props;
        if (passwordRequired || isNotEmpty(value)) {
            const msg = passwordValidator(value);
            if (msg) {
                callback(t(msg));
            }
        }
        form.validateFields(['oldPassword', 'password2'], { force: true });
        callback();
    };

    private validateConfirmation = (rule: any, value: any, callback: any): void => {
        const { form, passwordRequired } = this.props;
        const { t } = this.props;
        const password1 = form.getFieldValue('password1');
        if (!passwordRequired && !value && password1) {
            callback(t('Please confirm your password!'));
        }
        if (isNotEmpty(value) && value !== password1) {
            callback(t('Two passwords that you enter is inconsistent!'));
        } else {
            callback();
        }
    };

    private validateUsername = (_: any, value: any, callback: any): void => {
        const { t } = this.props;
        const msg = usernameValidator(value);
        if (msg) {
            callback(t(msg));
        }

        callback();
    };

    private validateAgrement = (agreement: any, value: any, callback: any): void => {
        const { userAgreements } = this.props;
        let isValid = true;
        for (const userAgreement of userAgreements) {
            if (agreement.field === userAgreement.name &&
                userAgreement.required && !value) {
                isValid = false;
                callback(`You must accept the ${userAgreement.displayText} to continue!`);
                break;
            }
        }
        if (isValid) {
            callback();
        }
    };

    private handleEmailSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        const {
            form,
            onEmailSubmit,
        } = this.props;

        form.validateFields((error, values): void => {
            const currentEmail = store.get('email');
            if (!error && values.email !== currentEmail) {
                onEmailSubmit(currentEmail, values.email);
            }
        });
    };

    private handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        const {
            form,
            onSubmit,
        } = this.props;

        form.validateFields((error, values): void => {
            if (!error) {
                onSubmit(values);
            }
        });
    };

    private renderFirstNameField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('firstName', {
                    initialValue: store.get('firstName') ? store.get('firstName') : initialData.firstName,
                    rules: [{
                        required: true,
                        message: t('Please specify a first name'),
                        // pattern: patterns.validateName.pattern,
                    }],
                })(
                    <Input
                        prefix={<Icon type='user-add' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('First name')}
                    />,
                )}
            </Form.Item>
        );
    }

    private renderLastNameField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('lastName', {
                    initialValue: store.get('lastName') ? store.get('lastName') : initialData.lastName,
                    rules: [{
                        required: true,
                        message: t('Please specify a last name'),
                        // pattern: patterns.validateName.pattern,
                    }],
                })(
                    <Input
                        prefix={<Icon type='user-add' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Last name')}
                    />,
                )}
            </Form.Item>
        );
    }

    private renderUsernameField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('userName', {
                    initialValue: store.get('userName') ? store.get('userName') : initialData.userName,
                    rules: [{
                        required: true,
                        message: t('Please specify a user name'),
                    }, {
                        validator: this.validateUsername,
                    }],
                })(
                    <Input
                        prefix={<Icon type='user-add' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Username')}
                    />,
                )}
            </Form.Item>
        );
    }

    private renderEmailField(): JSX.Element {
        const { form } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('email', {
                    initialValue: store.get('email'),
                    rules: [{
                        type: 'email',
                        message: t('The input is not valid E-mail!'),
                    }, {
                        required: true,
                        message: t('Please specify an email address'),
                    }],
                })(
                    <Input
                        autoComplete='email'
                        prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Email address')}
                        type='email'
                    />,
                )}
            </Form.Item>
        );
    }

    private renderOldPasswordField(): JSX.Element {
        const { form, passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('oldPassword', {
                    rules: [{
                        required: passwordRequired,
                        // message: t('Please input your old password!'),
                    }, {
                        validator: this.validateOldPassword,
                    }],
                })(<Input.Password
                    prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={t('Current password (if you want to change)')}
                />)}
            </Form.Item>
        );
    }

    private renderPasswordField(): JSX.Element {
        const { form, passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('password1', {
                    rules: [{
                        required: passwordRequired,
                        // message: t('Please input your password!'),
                    }, {
                        validator: this.validatePassword,
                    }],
                })(<Input.Password
                    autoComplete='new-password'
                    prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={passwordRequired ? t('Password') : t('New password')}
                />)}
            </Form.Item>
        );
    }

    private renderPasswordConfirmationField(): JSX.Element {
        const { form, passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('password2', {
                    rules: [{
                        required: passwordRequired,
                        // message: t('Please confirm your password!'),
                    }, {
                        validator: this.validateConfirmation,
                    }],
                })(<Input.Password
                    autoComplete='new-password'
                    prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={passwordRequired ? t('Confirm password') : t('Confirm new password')}
                />)}
            </Form.Item>
        );
    }

    private renderOrganizationField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('organization', {
                    initialValue: store.get('organization') ? store.get('organization') : initialData.organization,
                    rules: [{
                        required: true,
                        message: t('Please specify your organization'),
                    }],
                })(
                    <Input
                        prefix={<Icon type='team' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Organization')}
                    />,
                )}
            </Form.Item>
        );
    }

    private renderAddressField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('address', {
                    initialValue: store.get('address') ? store.get('address') : initialData.address,
                    rules: [{
                        required: true,
                        message: t('Please specify your address'),
                    }],
                })(
                    <Input
                        prefix={<Icon type='environment' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Address')}
                    />,
                )}
            </Form.Item>
        );
    }

    // private renderPhoneNumberField(): JSX.Element {
    //     const { form, initialData } = this.props;

    //     return (
    //         <Form.Item hasFeedback>
    //             {form.getFieldDecorator('phoneNumber', {
    //                 initialValue: initialData.phoneNumber,
    //                 rules: [{
    //                     required: true,
    //                     message: 'Please specify your phone number (e.g. +123456789012)',
    //                     pattern: patterns.validatePhone.pattern,
    //                 }],
    //             })(
    //                 <Input
    //                     prefix={<Icon type='user-add' style={{ color: 'rgba(0,0,0,.25)' }} />}
    //                     placeholder='Phone number'
    //                 />,
    //             )}
    //         </Form.Item>
    //     );
    // }

    private renderPurposeField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        return (
            <Form.Item hasFeedback>
                {form.getFieldDecorator('purpose', {
                    initialValue: initialData.purpose,
                    rules: [{
                        required: true,
                        message: t('Please describe your purpose of use'),
                    }],
                })(
                    <Input
                        prefix={<Icon type='bulb' style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={t('Purpose of use')}
                        suffix={(
                            <Tooltip title={t('EXAMPLES_OF_PURPOSE')} placement='topRight'>
                                <Icon type='info-circle' style={{ color: 'rgba(0,0,0,.45)' }} />
                            </Tooltip>
                        )}
                    />,
                )}
            </Form.Item>
        );
    }

    private renderLangField(): JSX.Element {
        const { form, initialData } = this.props;
        const { t } = this.props;

        let lang = store.get('lang');
        if (!lang) {
            if (initialData.lang) {
                lang = initialData.lang;
            } else {
                lang = Language.English;
            }
        }

        return (
            <Row className='ant-form-item'>
                <Col>
                    <Text className='cvat-text-color'>{t('Language')}</Text>
                    {form.getFieldDecorator('lang', {
                        initialValue: lang,
                        rules: [{
                            required: true,
                            message: t('Please specify a user name'),
                        }, {
                            validator: this.validateUsername,
                        }],
                    })(
                        <Select
                            onChange={(newLang: Language): void => {
                                changeLanguage(newLang);
                            }}
                        >
                            <Select.Option key='english' value={Language.English}>English</Select.Option>
                            <Select.Option key='japanese' value={Language.Japanese}>Japanese (日本語)</Select.Option>
                        </Select>,
                    )}
                </Col>
            </Row>
        );
    }

    private renderUserAgreements(): JSX.Element[] {
        const { form, userAgreements } = this.props;
        const { t } = this.props;
        const getUserAgreementsElements = (): JSX.Element[] => {
            const agreementsList: JSX.Element[] = [];
            for (const userAgreement of userAgreements) {
                agreementsList.push(
                    <Form.Item key={userAgreement.name}>
                        {form.getFieldDecorator(userAgreement.name, {
                            initialValue: false,
                            valuePropName: 'checked',
                            rules: [{
                                required: true,
                                message: t('You must accept to continue!'),
                            }, {
                                validator: this.validateAgrement,
                            }],
                        })(
                            <Checkbox>
                                {t('I read and accept the')}
                                {' '}
                                <a
                                    rel='noopener noreferrer'
                                    target='_blank'
                                    href={userAgreement.url}
                                >
                                    { userAgreement.displayText }
                                </a>
                            </Checkbox>,
                        )}
                    </Form.Item>,
                );
            }
            return agreementsList;
        };

        return getUserAgreementsElements();
    }

    private renderNameFields(): JSX.Element {
        if (this.getLanguage() === Language.Japanese) {
            return (
                <Row gutter={8}>
                    <Col span={12}>
                        {this.renderLastNameField()}
                    </Col>
                    <Col span={12}>
                        {this.renderFirstNameField()}
                    </Col>
                </Row>
            );
        }
        return (
            <Row gutter={8}>
                <Col span={12}>
                    {this.renderFirstNameField()}
                </Col>
                <Col span={12}>
                    {this.renderLastNameField()}
                </Col>
            </Row>
        );
    }

    public render(): JSX.Element {
        const { fetching, passwordRequired } = this.props;
        const { t } = this.props;

        return (
            <>
                <Row>
                    <Form onSubmit={this.handleEmailSubmit} className='login-form'>
                        <Row gutter={8}>
                            <Col span={16}>
                                {this.renderEmailField()}
                            </Col>
                            <Col span={8}>
                                <Form.Item>
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        className='register-form-button'
                                        loading={fetching}
                                        disabled={fetching}
                                    >
                                        {t('Update Email')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Row>
                <Row>
                    <Form onSubmit={this.handleSubmit} className='login-form'>
                        {this.renderNameFields()}
                        {this.renderUsernameField()}
                        {this.renderOrganizationField()}
                        {this.renderAddressField()}
                        {/* {this.renderPhoneNumberField()} */}
                        { !passwordRequired && this.renderOldPasswordField() }
                        {this.renderPasswordField()}
                        {this.renderPasswordConfirmationField()}
                        {this.renderPurposeField()}
                        {this.renderLangField()}
                        <Row type='flex' justify='end'>
                            <Form.Item>
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    className='register-form-button'
                                    loading={fetching}
                                    disabled={fetching}
                                >
                                    {t('Update')}
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>
                </Row>
            </>
        );
    }
}

export default withTranslation()(Form.create<UpdateProfileFormProps>()(UpdateProfileFormComponent));
