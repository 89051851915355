import React from 'react';

import Text from 'antd/lib/typography/Text';
import {
    Col,
    Row,
    Input,
    Form,
    Modal,
} from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

interface Props {
    fetching: boolean;
    verifying: boolean;
    oldEmail: string;
    newEmail: string;
    onVerify: (oldEmail: string, newEmail: string, code: string) => void;
    onCancel(oldEmail: string): void;
    t: TFunction;
}

interface State {code: string}

class VerificationModalComponent extends React.Component<Props & WithTranslation, State> {
    constructor(props: Props & WithTranslation) {
        super(props);
        this.state = {
            code: '',
        };
    }

    private handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        const {
            oldEmail,
            newEmail,
            onVerify,
        } = this.props;
        const { code } = this.state;

        onVerify(oldEmail, newEmail, code);
        this.setState({ code: '' });
    };

    private handleCancel = (): void => {
        const {
            oldEmail,
            onCancel,
        } = this.props;
        onCancel(oldEmail);
        this.setState({ code: '' });
    };

    private handleChange = (event: React.FormEvent): void => {
        this.setState({ code: event.target.value });
    };

    render(): JSX.Element | false {
        const {
            fetching,
            verifying,
            t,
        } = this.props;

        return (
            verifying && (
                <Modal
                    title={t('Verify your e-mail address')}
                    closable={false}
                    okType='primary'
                    okText='Verify'
                    onOk={this.handleSubmit}
                    onCancel={this.handleCancel}
                    okButtonProps={{ loading: fetching, disabled: fetching }}
                    cancelButtonProps={{ loading: fetching, disabled: fetching }}
                    visible
                >
                    <Row type='flex' justify='center' align='middle'>
                        <Col>
                            <Text>{t('A verification message will be sent to your new e-mail address soon. Please check your e-mail and enter verification code here.')}</Text>
                            <Form.Item>
                                <Input
                                    onChange={this.handleChange}
                                    placeholder={t('Verification code')}
                                    value={this.state.code}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Modal>
            )
        );
    }
}

export default withTranslation()(VerificationModalComponent);
