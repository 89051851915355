import React from 'react';
import { connect } from 'react-redux';
import { passwordResetAsync } from 'actions/auth-actions';
import PasswordResetComponent from 'components/password-reset';
import { CombinedState } from 'reducers/interfaces';


interface StateToProps {
    fetching: boolean;
}

function mapStateToProps(state: CombinedState): StateToProps {
    return {
        fetching: state.auth.fetching,
    };
}


function mapDispatchToProps(dispatch: any): any {
    return {
        onSubmit: (...args): void => dispatch(passwordResetAsync(...args)),
    };
}

function PasswordResetContainer(props: any): JSX.Element {
    return (
        <PasswordResetComponent {...props} />
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetContainer);
