import React from 'react'
import {connect} from 'react-redux';

import {CombinedState, Model} from "reducers/interfaces";
import HyperParametersComponent from "components/trained-model-page/hyper-parameters";

interface StateToProps {
}

interface DispatchToProps {
}

interface OwnProps {
    model: Model;
}

function mapStateToProps(state: CombinedState): StateToProps {
    return {};
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {};
}

function HyperParametersContainer(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    const {model} = props;

    return (
        <HyperParametersComponent model={model} />
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HyperParametersContainer)
