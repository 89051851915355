import React from 'react';

import { Col, Row } from 'antd/lib/grid';
import Alert from 'antd/lib/alert';

import getCore from 'cvat-core-wrapper';

const core = getCore();

interface Props {
    title: string;
    index: number;
    url: string;
    filename: string;
}

export default class ImageItem extends React.PureComponent<Props> {
    preview_image_url: any = null;

    public constructor(props: Props) {
        super(props);
        this.getImage();
    }

    private async getImage(): Promise<void> {
        const { url, filename } = this.props;
        const data = new FormData();
        data.append('filename', filename);
        const ab = await core.server.request(
            url, {
                method: 'POST',
                data,
                contentType: false,
                responseType: 'blob',
                processData: false,
            },
        );
        const blob = new Blob([ab], { type: 'image/jpeg' });
        const reader = new FileReader();
        reader.onloadend = () => {
            this.preview_image_url = (reader.result);
            this.forceUpdate();
        };
        reader.readAsDataURL(blob);
    }

    public renderImage(): JSX.Element {
        if (this.preview_image_url === null) {
            return (
                <Alert
                    message='No Data'
                    description='No images have been uploaded.'
                    type='info'
                    showIcon
                />
            );
        }
        return (
            <div>
                <img width='100%' src={this.preview_image_url} alt='No Data' />
            </div>
        );
    }

    public render(): JSX.Element {
        const { title } = this.props;
        return (
            <>
                <Row>
                    {`${title}`}
                </Row>
                <Row>
                    <Col>
                        {this.renderImage()}
                    </Col>
                </Row>
            </>
        );
    }
}
