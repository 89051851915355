import { boundariesActions, BoundariesActionTypes } from 'actions/boundaries-actions';
import { AdminAction, AdminActionTypes } from 'actions/admin-actions';
import { AdminState } from './interfaces';

const defaultState: AdminState = {
    initialized: false,
    fetching: false,
    users: [],
};

export default function (state = defaultState, action: AdminAction | boundariesActions): AdminState {
    switch (action.type) {
        case AdminActionTypes.GET_COGNITO_USERS:
            return {
                ...state,
                initialized: false,
                fetching: true,
            };
        case AdminActionTypes.GET_COGNITO_USERS_SUCCESS:
            return {
                ...state,
                initialized: true,
                fetching: false,
                users: action.payload.users,
            };
        case AdminActionTypes.GET_COGNITO_USERS_FAILED:
            return {
                ...state,
                initialized: true,
                fetching: false,
            };
        case BoundariesActionTypes.RESET_AFTER_ERROR:
            return { ...defaultState };
        default:
            return state;
    }
}
