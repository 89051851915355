// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row, Col } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import { Model } from 'reducers/interfaces';
import BuiltModelItemComponent from './built-model-item';

interface Props {
    models: Model[];
}

export default function IntegratedModelsListComponent(props: Props): JSX.Element {
    const { models } = props;
    const items = models.map((model): JSX.Element => (
        <BuiltModelItemComponent key={model.name} model={model} />
    ));

    const { t } = useTranslation();

    return (
        <Row type='flex' justify='center' align='middle'>
            <Col xs={23} sm={22} md={22} lg={18} xl={16} xxl={14} className='cvat-models-list'>
                <Row type='flex' justify='start' align='middle'>
                    <Col>
                        <Text className='cvat-text-color' strong>{t('PreTrained Models')}</Text>
                    </Col>
                </Row>
                <Row type='flex' align='middle' style={{ padding: '10px' }}>
                    <Col span={4}>
                        <Text strong>{t('Framework')}</Text>
                    </Col>
                    <Col span={10}>
                        <Text strong>{t('Name')}</Text>
                    </Col>
                    <Col span={10}>
                        <Text strong>{t('Labels')}</Text>
                    </Col>
                </Row>
                { items }
            </Col>
        </Row>
    );
}
